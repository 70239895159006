const fetchTokenApi = async (api, params) => {
  const result = await api.fetchToken(params)
  const { refresh_token, expires_in, access_token } = result
  const tokenInfo = {
    refreshToken: refresh_token,
    expiresIn: expires_in,
    token: access_token,
  }
  return tokenInfo
}

const Token = ({ clientId, redirectUri, onError }, api) => {
  let tokenInfo = null

  const getToken = async ({ code }) => {
    try {
      const result = await fetchTokenApi(api, {
        code,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code',
        client_id: clientId,
      })
      tokenInfo = Object.assign({}, result)
      return result
    } catch (error) {
      onError && onError(error)
      throw error
    }
  }

  const refreshToken = async () => {
    try {
      const { refreshToken } = tokenInfo || {}
      const result = await fetchTokenApi(api, {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: clientId,
      })
      tokenInfo = Object.assign({}, result)
      return result
    } catch (error) {
      onError && onError(error)
      throw error
    }
  }

  const revokeToken = async () => {
    const { token } = tokenInfo || {}
    tokenInfo = null
    try {
      await api.revokeToken(token)
    } catch (e) {}
  }

  return {
    getToken,
    refreshToken,
    revokeToken,
  }
}

export default Token
