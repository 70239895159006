/** @format */

import React from "react";
import { useParams } from "react-router";
import { Navigate } from "react-router";
import { SetInvitation } from "store/invitation";
import * as R from "ramda";
import { routerName, eqrcodeVersion } from "constants/env";

const Invitation = () => {
  let url = new URL(window.location.href);
  SetInvitation(url.pathname);
  return <Navigate to="/login" />;
};

export const Invitation2 = () => {
  const params = useParams();
  const invitationId = R.path(["invitationId"], params);
  const project = R.path(["project"], params);

  return (
    <Navigate
      to={`/${
        eqrcodeVersion === "true" ? routerName : project
      }/invitation/${invitationId}`}
    />
  );
};
export default Invitation;
