/** @format */
import React from "react";
import { Button, Input, Modal, Select, Tabs } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const StyleInput = styled(Input)`
  border-radius: 2px !important;
`;

export const StyleSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 2px !important;
  }
`;

export const StyleTextArea = styled(TextArea)`
  border-radius: 2px !important;
`;

const StyleButtonBase = styled(Button)`
  font-size: 14px !important;
  font-weight: 400 !important;
  border: 0 !important;
  border-radius: 2px !important;
  padding: 5px 16px !important;
  :hover {
    background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ) !important;
  }
  :active {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ) !important;
  }
  :disabled {
    background: #949494 !important;
  }
`;

export const GreyButton = styled(StyleButtonBase)`
  background: #e5e6eb !important;
  color: #4f4f4f !important;
`;

export const LineButton = styled(StyleButtonBase)`
  border: 1px solid #229bff !important;
  color: #229bff !important;
`;

export const BlueButton = styled(StyleButtonBase)`
  background: #229bff !important;
  color: #ffffff !important;
`;

export const CreateButton = styled(BlueButton)`
  min-width: 95px;
`;

export const DeleteButton = styled(StyleButtonBase)`
  background: #f53f3f !important;
  color: #ffffff !important;
`;

export const StyledIconButton = styled(Button)`
  background: none !important;
  border: none;
  margin-left: 0px;
  border: 0;
  padding: 5px 5px 5px 5px !important;
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: #229bff !important;
  }
  .ant-tabs-nav {
    padding: 0 16px !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid #e5e6eb !important;
  }
  .ant-tabs-tab {
    height: 40px !important;
    padding: 1px 8px !important;
  }
  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    border-radius: 2px !important;
    color: #4e5969 !important;
    :hover {
      background: #f2f3f5 !important;
      background-image: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ) !important;
    }
    :active {
      background: #f2f3f5 !important;
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1)
      ) !important;
    }
    & .ant-tabs-tab-btn:active {
      color: #4e5969 !important;
    }
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #229bff !important;
    font-weight: 400;
  }
`;

export const StyleModal = styled(Modal)`
  width: 464px !important;
  .ant-modal-content {
    border-radius: 4px !important;
    padding: 25px 32px;
  }
`;
