const OAuth = ({ clientId, acrValues = 'urn:hpbp:hpid', redirectUri, onError }, api) => {
  const loginWithEmail = async (email = '') => {
    const encodeEmail = encodeURIComponent(email)
    const params = {
      response_type: 'code',
      client_id: clientId,
      max_age: 0,
      state: 'isv_state',
      redirect_uri: redirectUri,
    }

    const isHpEmail =
      email.substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.')).toLowerCase() === 'hp'

    try {
      const response = await api.getHPIDUrl(params)
      const query = isHpEmail
          ? '&acr_values=onehp'
          : `&login_hint=${encodeEmail}&email=${encodeEmail}&target=password&allow_return=false`
  
      window.location = `${response}&max_age=0&locale=en${query}`
      return true
    } catch(error) {
      onError && onError(error)
      throw error
    }
  }

  const loginWithoutEmail = () => {
    const params = {
      response_type: 'code',
      state: 'isv_state',
      client_id: clientId,
      max_age: 0,
      acr_values: acrValues,
      redirect_uri: redirectUri,
    }

    const url = api.getHPIDOpenUrl(params)
    window.open(url, '_self')
    return true
  }

  return {
    loginWithEmail,
    loginWithoutEmail,
  }
}

export default OAuth
