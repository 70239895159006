/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const Insight = forwardRef(
  ({ width = 24, height = 24, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <circle cx="12" cy="12" r="12" fill="url(#paint0_angular_817_60002)" />
      <path
        d="M24 12C24 9.09304 22.9448 6.2849 21.0304 4.09732L12 12L24 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 18.6274 5.37258 24 12 24L12 4.72365e-10C5.37258 2.11485e-10 0 5.37258 0 12Z"
        fill="url(#paint1_linear_817_60002)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_817_60002"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12 12) rotate(90) scale(12)"
        >
          <stop stopColor="#229BFF" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_817_60002"
          x1="0.585365"
          y1="24"
          x2="19.4133"
          y2="14.1033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#229BFF" />
          <stop offset="1" stopColor="#6666FF" />
        </linearGradient>
      </defs>
    </svg>
  )
);

export default Insight;
