/** @format */

import { useState, useEffect } from "react";

export function getStorageValue(key: string, defaultValue?: string | null) {
  // getting stored value
  if (typeof window !== "undefined") {
    const saved: any = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

export function setStorageValue(key: string, defaultValue: string | null) {
  localStorage.setItem(key, JSON.stringify(defaultValue));
}

export const useLocalStorage = (key: string, defaultValue: string | null) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
