import React, { useEffect } from "react";
import nprogress from "nprogress";
import "nprogress/nprogress.css";

const NProgress: React.FC = () => {
  useEffect(() => {
    nprogress.configure({ showSpinner: false });
    nprogress.start();
    return () => {
      nprogress.done();
    };
  }, []);

  return <></>;
};

export default NProgress;
