export interface InvitationCallback {
  url?: string;
}

const GetStorigeValues = () => {
  let valueJson: string = localStorage.getItem("persist:console") || "{}";
  let persistConsole = JSON.parse(valueJson);
  return persistConsole || {};
};

const SetStorigeValues = (obj: any) => {
  localStorage.setItem("persist:console", JSON.stringify(obj));
};
export const GetInvitation = () => {
  let persistConsole = GetStorigeValues();
  let invitationCallback: InvitationCallback | undefined =
    persistConsole.invitationCallback
      ? (persistConsole.invitationCallback as InvitationCallback)
      : undefined;
  return invitationCallback;
};

export const SetInvitation = (url: string | undefined) => {
  let values = GetStorigeValues();
  let invitationCallback: InvitationCallback = { url: url };
  values.invitationCallback = invitationCallback;
  SetStorigeValues(values);
};
export const ClearInvitation = () => {
  SetInvitation(undefined);
};
