import qs from "qs";

import { keysToCamel } from "utils/stringUtil";
import actions from "components/shared/actions";
import API, { queryToString } from "./api";
import OAuth from "./oauth";
import Token from "./token/token";
import TokenManager from "./token/manager";

const HPIDClient = (options = {}) => {
  const {
    apiBase = "https://oauth-dev.hpbp.io",
    oAuthApiBase = "https://hpbp-dev.hpbp.io/gaia/api",
    logoutApiBase = "https://directory.id.hp.com/directory/v1/oauth/logout?post_logout_redirect_uri=",
    clientId,
    acrValues = "urn:hpbp:hpid",
    loginRedirectUri = "/oauth",
    logoutRedirectUri = "/logout",
    logoutCallback = () => {},
    onError = () => {}
  } = options;

  const oauthCallbackUri = loginRedirectUri.startsWith("http")
    ? loginRedirectUri
    : `${window.location.origin}${loginRedirectUri}`;
  const logoutCallbackUri = logoutRedirectUri.startsWith("http")
    ? logoutRedirectUri
    : `${window.location.origin}${logoutRedirectUri}`;
  if (!oAuthApiBase) {
    throw new Error("oAuthApiBase is required.");
  }
  if (!logoutApiBase) {
    throw new Error("logoutApiBase is required.");
  }
  if (!clientId) {
    throw new Error("clientId is required.");
  }

  const api = API({ oAuthApiBase, apiBase, clientId, onError });
  const oAuth = OAuth(
    { clientId, acrValues, redirectUri: oauthCallbackUri, onError },
    api
  );
  const token = Token(
    { clientId, redirectUri: oauthCallbackUri, onError },
    api
  );

  const login = (email = "") => {
    if (email) {
      return oAuth.loginWithEmail(email);
    } else {
      return oAuth.loginWithoutEmail();
    }
  };

  const tokenInst = () => {
    return {
      getToken() {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        return token.getToken({ code });
      },
      refreshToken: token.refreshToken,
      async logout() {
        await token.revokeToken();
        window.location.replace(`${logoutApiBase}${logoutCallbackUri}`);
      }
    };
  };

  const tokenManagerInst = (options = {}) => {
    const {
      prefix,
      focusLogoutTime,
      onGetToken = null,
      onRefreshToken = null,
      onLogout = null
    } = options;
    const customApis = {
      onGetToken,
      onRefreshToken,
      onLogout
    };
    const manager = TokenManager(
      {
        prefix,
        clientId,
        focusLogoutTime,
        redirectUri: oauthCallbackUri,
        customApis,
        onError
      },
      api
    );

    return {
      setSpecialApi({ onGetToken, onRefreshToken, onLogout }) {
        if (onGetToken) {
          customApis.onGetToken = onGetToken;
        }
        if (onRefreshToken) {
          customApis.onRefreshToken = onRefreshToken;
        }
        if (onLogout) {
          customApis.onLogout = onLogout;
        }
      },
      loadLocalData() {
        return manager.initLastLogin();
      },
      cleanLocalData() {
        manager.cleanLocalData();
      },
      async initTokenManager() {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");
        const state = params.get("state");
        const scope = params.get("scope");
        const invitationCode = params.get("invitation_code");
        return await manager.fetchLoginToken({
          code,
          state,
          scope,
          invitationCode
        });
      },
      getAndLockToken: manager.getAndLockToken,
      unlockToken: manager.unlockToken,
      getToken: manager.getToken,
      testRefreshToken: manager.testRefreshToken,
      async logout() {
        actions.setGlobalState({ isLogout: true });
        await manager.logout();
        logoutCallback();
        if (!customApis.onLogout) {
          window.location.replace(`${logoutApiBase}${logoutCallbackUri}`);
        }
      },
      creator: {
        fetchCreator({ apiBase, needToken }) {
          return async ({
            url,
            queryParams,
            body,
            headers,
            method = "GET"
          }) => {
            const _headers = {
              "content-type":
                "application/x-www-form-urlencoded; charset=utf-8", // 'application/json',
              ...(headers || {})
            };
            const query = queryToString(queryParams);
            if (needToken) {
              const { token } = await manager.getAndLockToken();
              headers = {
                ..._headers,
                authorization: token || `Bearer ${this.getToken()}`
              };
            }
            const options = {
              method,
              body: body ? qs.stringify(body) : null,
              headers
            };
            try {
              const resp = await fetch(`${apiBase}${url}${query}`, options);
              let data = {};
              try {
                data = keysToCamel(await resp.text());
              } catch (e) {}
              if (resp.status >= 400) {
                throw data;
              }
              return data;
            } catch (error) {
              if (onError) {
                onError(error);
              }
              throw error;
            } finally {
              if (needToken) {
                manager.unlockToken();
              }
            }
          };
        }
      }
    };
  };

  return {
    login,
    token: tokenInst,
    tokenManager: tokenManagerInst
  };
};

export default HPIDClient;
