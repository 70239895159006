/** @format */

import CryptoJS from "crypto-js";

export const encrypt = (text: string, key: string) => {
  const encrypted = CryptoJS.AES.encrypt(text, key);
  return encrypted.toString();
};

export const decrypt = (encryptedText: string, key: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedText, key);
  return decrypted.toString(CryptoJS.enc.Utf8);
};
