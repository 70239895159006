/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const SPLogo = forwardRef(
  ({ width = 135, height = 44, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 135 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <rect
        x="0.594287"
        y="0.2"
        width="43.6"
        height="43.6"
        rx="10.3926"
        fill="url(#paint0_linear_4865_36689)"
        stroke="#E1E7FF"
        strokeWidth="0.4"
      />
      <g opacity="0.1">
        <mask
          id="mask0_4865_36689"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="9"
          width="25"
          height="26"
        >
          <rect
            x="10.1925"
            y="9.7982"
            width="24.4037"
            height="24.4037"
            rx="2.42407"
            fill="#C4C4C4"
            stroke="#0A2246"
            strokeWidth="0.0407407"
          />
        </mask>
        <g mask="url(#mask0_4865_36689)">
          <path
            d="M19.5251 10.9011V9.81857H20.6077V10.9011H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 10.9011V9.81857H21.7715V10.9011H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 10.9011V9.81857H22.9358V10.9011H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 10.9011V9.81857H25.2637V10.9011H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 12.0652V10.9826H21.7715V12.0652H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 12.0652V10.9826H22.9358V12.0652H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 13.2292V12.1467H22.9358V13.2292H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 14.3933V13.3108H20.6077V14.3933H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 14.3933V13.3108H24.0994V14.3933H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 14.3933V13.3108H25.2637V14.3933H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 15.5574V14.4748H22.9358V15.5574H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 15.5574V14.4748H24.0994V15.5574H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 16.7214V15.6389H21.7715V16.7214H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 16.7214V15.6389H24.0994V16.7214H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 17.885V16.8025H20.6077V17.885H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 17.885V16.8025H22.9358V17.885H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 17.885V16.8025H25.2637V17.885H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 19.0491V17.9665H21.7715V19.0491H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 19.0491V17.9665H25.2637V19.0491H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M12.541 20.2131V19.1306H13.6235V20.2131H12.541Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M14.8689 20.2131V19.1306H15.9514V20.2131H14.8689Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M16.0329 20.2131V19.1306H17.1155V20.2131H16.0329Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M17.197 20.2131V19.1306H18.2795V20.2131H17.197Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 20.2131V19.1306H20.6077V20.2131H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 20.2131V19.1306H21.7715V20.2131H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 20.2131V19.1306H26.4277V20.2131H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 20.2131V19.1306H31.0837V20.2131H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 20.2131V19.1306H34.5759V20.2131H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M13.705 21.3772V20.2946H14.7876V21.3772H13.705Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M14.8689 21.3772V20.2946H15.9514V21.3772H14.8689Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M18.3611 21.3772V20.2946H19.4436V21.3772H18.3611Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 21.3772V20.2946H20.6077V21.3772H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 21.3772V20.2946H25.2637V21.3772H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 21.3772V20.2946H27.5915V21.3772H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 21.3772V20.2946H28.7556V21.3772H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 21.3772V20.2946H33.4116V21.3772H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 21.3772V20.2946H34.5759V21.3772H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M10.2129 22.5412V21.4587H11.2954V22.5412H10.2129Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M11.3767 22.5412V21.4587H12.4592V22.5412H11.3767Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M12.541 22.5412V21.4587H13.6235V22.5412H12.541Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M13.705 22.5412V21.4587H14.7876V22.5412H13.705Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M14.8689 22.5412V21.4587H15.9514V22.5412H14.8689Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M17.197 22.5412V21.4587H18.2795V22.5412H17.197Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 22.5412V21.4587H20.6077V22.5412H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 22.5412V21.4587H25.2637V22.5412H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 22.5412V21.4587H26.4277V22.5412H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 22.5412V21.4587H27.5915V22.5412H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 22.5412V21.4587H28.7556V22.5412H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 22.5412V21.4587H31.0837V22.5412H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M31.165 22.5412V21.4587H32.2476V22.5412H31.165Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 22.5412V21.4587H33.4116V22.5412H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 22.5412V21.4587H34.5759V22.5412H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M10.2129 23.7053V22.6228H11.2954V23.7053H10.2129Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M11.3767 23.7053V22.6228H12.4592V23.7053H11.3767Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M12.541 23.7053V22.6228H13.6235V23.7053H12.541Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M13.705 23.7053V22.6228H14.7876V23.7053H13.705Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M16.0329 23.7053V22.6228H17.1155V23.7053H16.0329Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 23.7053V22.6228H20.6077V23.7053H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 23.7053V22.6228H21.7715V23.7053H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 23.7053V22.6228H24.0994V23.7053H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 23.7053V22.6228H25.2637V23.7053H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 23.7053V22.6228H28.7556V23.7053H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 23.7053V22.6228H29.9197V23.7053H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 23.7053V22.6228H33.4116V23.7053H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M10.2129 24.8689V23.7863H11.2954V24.8689H10.2129Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M11.3767 24.8689V23.7863H12.4592V24.8689H11.3767Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M16.0329 24.8689V23.7863H17.1155V24.8689H16.0329Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M17.197 24.8689V23.7863H18.2795V24.8689H17.197Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 24.8689V23.7863H20.6077V24.8689H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 24.8689V23.7863H21.7715V24.8689H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 24.8689V23.7863H22.9358V24.8689H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 24.8689V23.7863H28.7556V24.8689H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 24.8689V23.7863H31.0837V24.8689H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 24.8689V23.7863H33.4116V24.8689H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 24.8689V23.7863H34.5759V24.8689H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 26.0334V24.9509H20.6077V26.0334H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 26.0334V24.9509H22.9358V26.0334H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 26.0334V24.9509H24.0994V26.0334H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 26.0334V24.9509H25.2637V26.0334H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 26.0334V24.9509H31.0837V26.0334H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 26.0334V24.9509H34.5759V26.0334H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 27.1975V26.115H21.7715V27.1975H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 27.1975V26.115H22.9358V27.1975H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 27.1975V26.115H29.9197V27.1975H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 27.1975V26.115H31.0837V27.1975H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 27.1975V26.115H33.4116V27.1975H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 27.1975V26.115H34.5759V27.1975H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 28.3616V27.279H20.6077V28.3616H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 28.3616V27.279H21.7715V28.3616H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 28.3616V27.279H22.9358V28.3616H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 28.3616V27.279H25.2637V28.3616H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 28.3616V27.279H27.5915V28.3616H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 28.3616V27.279H33.4116V28.3616H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 29.5251V28.4426H20.6077V29.5251H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 29.5251V28.4426H22.9358V29.5251H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 29.5251V28.4426H24.0994V29.5251H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 29.5251V28.4426H27.5915V29.5251H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 29.5251V28.4426H29.9197V29.5251H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 29.5251V28.4426H31.0837V29.5251H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 29.5251V28.4426H33.4116V29.5251H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 29.5251V28.4426H34.5759V29.5251H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 30.6892V29.6067H21.7715V30.6892H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 30.6892V29.6067H27.5915V30.6892H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 30.6892V29.6067H28.7556V30.6892H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 30.6892V29.6067H33.4116V30.6892H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 31.8533V30.7707H20.6077V31.8533H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 31.8533V30.7707H25.2637V31.8533H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 31.8533V30.7707H26.4277V31.8533H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 31.8533V30.7707H28.7556V31.8533H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M31.165 31.8533V30.7707H32.2476V31.8533H31.165Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 31.8533V30.7707H34.5759V31.8533H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 33.0173V31.9348H21.7715V33.0173H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 33.0173V31.9348H22.9358V33.0173H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 33.0173V31.9348H24.0994V33.0173H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 33.0173V31.9348H26.4277V33.0173H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 33.0173V31.9348H27.5915V33.0173H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 33.0173V31.9348H28.7556V33.0173H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 33.0173V31.9348H29.9197V33.0173H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 33.0173V31.9348H31.0837V33.0173H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 33.0173V31.9348H33.4116V33.0173H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 34.1814V33.0988H22.9358V34.1814H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 34.1814V33.0988H26.4277V34.1814H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 34.1814V33.0988H27.5915V34.1814H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 34.1814V33.0988H28.7556V34.1814H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M31.165 34.1814V33.0988H32.2476V34.1814H31.165Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 34.1814V33.0988H33.4116V34.1814H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 34.1814V33.0988H34.5759V34.1406C34.5759 34.1631 34.5577 34.1814 34.5352 34.1814H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8314 12.1117H30.6735C31.5622 12.1117 32.2827 12.8322 32.2827 13.7209V15.563H28.8314V12.1117Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0407407"
          />
          <path
            d="M15.9572 12.1117H14.1151C13.2263 12.1117 12.5058 12.8322 12.5058 13.7209V15.563H15.9572V12.1117Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0407407"
          />
          <path
            d="M16.0009 31.8883H14.1588C13.27 31.8883 12.5495 31.1678 12.5495 30.2791V28.437H16.0009V31.8883Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0407407"
          />
          <path
            d="M27.0272 10.3075H31.3573C32.8649 10.3075 34.087 11.5296 34.087 13.0371V17.3672H27.0272V10.3075Z"
            stroke="#0A2246"
            strokeWidth="1.05926"
          />
          <path
            d="M17.7614 10.3075H13.4312C11.9237 10.3075 10.7016 11.5296 10.7016 13.0371V17.3672H17.7614V10.3075Z"
            stroke="#0A2246"
            strokeWidth="1.05926"
          />
          <path
            d="M17.8051 33.6925H13.4749C11.9674 33.6925 10.7453 32.4704 10.7453 30.9629V26.6328H17.8051V33.6925Z"
            stroke="#0A2246"
            strokeWidth="1.05926"
          />
        </g>
      </g>
      <g opacity="0.6">
        <mask
          id="mask1_4865_36689"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="22"
          width="25"
          height="13"
        >
          <path
            d="M10.1925 22.0204H34.5962V31.7778C34.5962 33.1166 33.5109 34.2019 32.1721 34.2019H12.6166C11.2778 34.2019 10.1925 33.1166 10.1925 31.7778V22.0204Z"
            fill="#C4C4C4"
            stroke="#0096D6"
            strokeWidth="0.0407407"
          />
        </mask>
        <g mask="url(#mask1_4865_36689)">
          <path
            d="M10.2129 22.5412V21.4587H11.2954V22.5412H10.2129Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M11.3767 22.5412V21.4587H12.4592V22.5412H11.3767Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M12.541 22.5412V21.4587H13.6235V22.5412H12.541Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M13.705 22.5412V21.4587H14.7876V22.5412H13.705Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M14.8689 22.5412V21.4587H15.9514V22.5412H14.8689Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M17.197 22.5412V21.4587H18.2795V22.5412H17.197Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 22.5412V21.4587H20.6077V22.5412H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 22.5412V21.4587H25.2637V22.5412H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 22.5412V21.4587H26.4277V22.5412H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 22.5412V21.4587H27.5915V22.5412H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 22.5412V21.4587H28.7556V22.5412H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 22.5412V21.4587H31.0837V22.5412H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M31.165 22.5412V21.4587H32.2476V22.5412H31.165Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 22.5412V21.4587H33.4116V22.5412H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 22.5412V21.4587H34.5759V22.5412H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M10.2129 23.7053V22.6228H11.2954V23.7053H10.2129Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M11.3767 23.7053V22.6228H12.4592V23.7053H11.3767Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M12.541 23.7053V22.6228H13.6235V23.7053H12.541Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M13.705 23.7053V22.6228H14.7876V23.7053H13.705Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M16.0329 23.7053V22.6228H17.1155V23.7053H16.0329Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 23.7053V22.6228H20.6077V23.7053H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 23.7053V22.6228H21.7715V23.7053H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 23.7053V22.6228H24.0994V23.7053H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 23.7053V22.6228H25.2637V23.7053H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 23.7053V22.6228H28.7556V23.7053H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 23.7053V22.6228H29.9197V23.7053H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 23.7053V22.6228H33.4116V23.7053H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M10.2129 24.8689V23.7863H11.2954V24.8689H10.2129Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M11.3767 24.8689V23.7863H12.4592V24.8689H11.3767Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M16.0329 24.8689V23.7863H17.1155V24.8689H16.0329Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M17.197 24.8689V23.7863H18.2795V24.8689H17.197Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 24.8689V23.7863H20.6077V24.8689H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 24.8689V23.7863H21.7715V24.8689H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 24.8689V23.7863H22.9358V24.8689H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 24.8689V23.7863H28.7556V24.8689H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 24.8689V23.7863H31.0837V24.8689H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 24.8689V23.7863H33.4116V24.8689H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 24.8689V23.7863H34.5759V24.8689H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 26.0334V24.9509H20.6077V26.0334H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 26.0334V24.9509H22.9358V26.0334H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 26.0334V24.9509H24.0994V26.0334H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 26.0334V24.9509H25.2637V26.0334H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 26.0334V24.9509H31.0837V26.0334H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 26.0334V24.9509H34.5759V26.0334H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 27.1975V26.115H21.7715V27.1975H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 27.1975V26.115H22.9358V27.1975H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 27.1975V26.115H29.9197V27.1975H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 27.1975V26.115H31.0837V27.1975H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 27.1975V26.115H33.4116V27.1975H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 27.1975V26.115H34.5759V27.1975H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 28.3616V27.279H20.6077V28.3616H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 28.3616V27.279H21.7715V28.3616H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 28.3616V27.279H22.9358V28.3616H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 28.3616V27.279H25.2637V28.3616H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 28.3616V27.279H27.5915V28.3616H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 28.3616V27.279H33.4116V28.3616H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 29.5251V28.4426H20.6077V29.5251H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 29.5251V28.4426H22.9358V29.5251H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 29.5251V28.4426H24.0994V29.5251H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 29.5251V28.4426H27.5915V29.5251H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 29.5251V28.4426H29.9197V29.5251H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 29.5251V28.4426H31.0837V29.5251H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 29.5251V28.4426H33.4116V29.5251H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 29.5251V28.4426H34.5759V29.5251H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 30.6892V29.6067H21.7715V30.6892H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 30.6892V29.6067H27.5915V30.6892H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 30.6892V29.6067H28.7556V30.6892H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 30.6892V29.6067H33.4116V30.6892H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M19.5251 31.8533V30.7707H20.6077V31.8533H19.5251Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M24.1811 31.8533V30.7707H25.2637V31.8533H24.1811Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 31.8533V30.7707H26.4277V31.8533H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 31.8533V30.7707H28.7556V31.8533H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M31.165 31.8533V30.7707H32.2476V31.8533H31.165Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 31.8533V30.7707H34.5759V31.8533H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M20.6889 33.0173V31.9348H21.7715V33.0173H20.6889Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 33.0173V31.9348H22.9358V33.0173H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M23.0168 33.0173V31.9348H24.0994V33.0173H23.0168Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 33.0173V31.9348H26.4277V33.0173H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 33.0173V31.9348H27.5915V33.0173H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 33.0173V31.9348H28.7556V33.0173H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M28.8371 33.0173V31.9348H29.9197V33.0173H28.8371Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M30.0012 33.0173V31.9348H31.0837V33.0173H30.0012Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 33.0173V31.9348H33.4116V33.0173H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M21.8532 34.1814V33.0988H22.9358V34.1814H21.8532Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M25.3452 34.1814V33.0988H26.4277V34.1814H25.3452Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M26.509 34.1814V33.0988H27.5915V34.1814H26.509Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M27.6731 34.1814V33.0988H28.7556V34.1814H27.6731Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M31.165 34.1814V33.0988H32.2476V34.1814H31.165Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M32.3291 34.1814V33.0988H33.4116V34.1814H32.3291Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M33.4934 34.1814V33.0988H34.5759V34.1406C34.5759 34.1631 34.5577 34.1814 34.5352 34.1814H33.4934Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0814815"
          />
          <path
            d="M16.0009 31.8883H14.1588C13.27 31.8883 12.5495 31.1678 12.5495 30.2791V28.437H16.0009V31.8883Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0407407"
          />
          <path
            d="M17.8051 33.6925H13.4749C11.9674 33.6925 10.7453 32.4704 10.7453 30.9629V26.6328H17.8051V33.6925Z"
            stroke="#0A2246"
            strokeWidth="1.05926"
          />
        </g>
      </g>
      <rect
        x="10.1721"
        y="20.0444"
        width="24.4444"
        height="3.91111"
        fill="white"
      />
      <path
        d="M4.95703 22H39.8311V38.9481C39.8311 39.2182 39.6122 39.437 39.3422 39.437H5.44592C5.17591 39.437 4.95703 39.2182 4.95703 38.9481V22Z"
        fill="url(#paint1_linear_4865_36689)"
      />
      <g filter="url(#filter0_d_4865_36689)">
        <path
          d="M29.3762 4.97021H32.9052C36.5053 4.97021 39.4238 7.88865 39.4238 11.4887V15.0178M29.3762 39.0295H32.9052C36.5053 39.0295 39.4238 36.111 39.4238 32.511V28.9819M15.412 39.0295H11.883C8.28294 39.0295 5.3645 36.111 5.3645 32.511L5.3645 28.9819M5.3645 15.0178L5.3645 11.4887C5.3645 7.88865 8.28294 4.97021 11.883 4.97021H15.412"
          stroke="url(#paint2_linear_4865_36689)"
          strokeWidth="2.28148"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="2.92017"
        y="21.022"
        width="38.9481"
        height="1.95556"
        rx="0.977778"
        fill="url(#paint3_linear_4865_36689)"
      />
      <mask
        id="path-233-outside-1_4865_36689"
        maskUnits="userSpaceOnUse"
        x="51.3943"
        y="6.8374"
        width="83"
        height="30"
        fill="black"
      >
        <rect fill="white" x="51.3943" y="6.8374" width="83" height="30" />
        <path d="M59.0993 7.8374C60.1393 7.8474 60.9393 8.0974 61.4993 8.5874C62.0693 9.0674 62.3543 9.7624 62.3543 10.6724C62.3543 10.9224 62.3443 11.1174 62.3243 11.2574L62.2343 12.0224C62.0943 13.1224 61.6743 13.9674 60.9743 14.5574C60.2743 15.1374 59.3393 15.4324 58.1693 15.4424H55.1093L54.6593 19.1024H52.8893L54.2693 7.8374H59.0993ZM58.4093 13.7924C59.0293 13.7824 59.5043 13.6474 59.8343 13.3874C60.1643 13.1174 60.3693 12.6924 60.4493 12.1124L60.5693 11.1974C60.5793 11.1274 60.5843 11.0324 60.5843 10.9124C60.5843 10.4224 60.4493 10.0674 60.1793 9.8474C59.9193 9.6174 59.5093 9.4974 58.9493 9.4874H55.8443L55.3043 13.7924H58.4093Z" />
        <path d="M70.8703 7.8374C71.8903 7.8474 72.6753 8.0874 73.2253 8.5574C73.7753 9.0174 74.0503 9.6874 74.0503 10.5674C74.0503 10.6774 74.0403 10.8524 74.0203 11.0924L73.9603 11.5874C73.8403 12.4974 73.5203 13.2174 73.0003 13.7474C72.4903 14.2774 71.8003 14.6124 70.9303 14.7524L73.9903 19.1024H71.8303L68.9803 14.8274H66.9253L66.4003 19.1024H64.6303L66.0103 7.8374H70.8703ZM70.2553 13.2074C70.8253 13.1974 71.2653 13.0774 71.5753 12.8474C71.8953 12.6174 72.0903 12.2224 72.1603 11.6624L72.2503 11.0174C72.2603 10.9474 72.2653 10.8524 72.2653 10.7324C72.2653 10.2824 72.1353 9.9624 71.8753 9.7724C71.6153 9.5724 71.2303 9.4674 70.7203 9.4574H67.5853L67.1203 13.2074H70.2553Z" />
        <path d="M78.2933 7.8374H85.5533L85.3583 9.4574H79.8683L79.4783 12.5924H84.2783L84.0833 14.1974H79.2833L78.8783 17.4674H84.5033L84.3083 19.1024H76.9133L78.2933 7.8374Z" />
        <path d="M90.915 7.8374L93.39 17.0474H93.45L98.205 7.8374H100.155L98.775 19.1024H97.155L98.13 11.1524L94.245 18.6374C94.085 18.9474 93.835 19.1024 93.495 19.1024H92.88C92.53 19.1024 92.32 18.9474 92.25 18.6374L90.18 11.1524L89.205 19.1024H87.585L88.965 7.8374H90.915Z" />
        <path d="M103.943 7.8374H105.713L104.333 19.1024H102.563L103.943 7.8374Z" />
        <path d="M113.148 17.5124C114.078 17.5024 114.768 17.2724 115.218 16.8224C115.668 16.3724 115.963 15.6424 116.103 14.6324L116.928 7.8374H118.698L117.888 14.5424C117.678 16.1524 117.183 17.3224 116.403 18.0524C115.623 18.7824 114.473 19.1524 112.953 19.1624H112.368C111.038 19.1524 110.063 18.8674 109.443 18.3074C108.823 17.7474 108.513 16.8674 108.513 15.6674C108.513 15.3674 108.538 14.9924 108.588 14.5424L109.413 7.8374H111.183L110.358 14.6324C110.318 15.0324 110.298 15.3024 110.298 15.4424C110.298 16.1724 110.473 16.6974 110.823 17.0174C111.173 17.3374 111.753 17.5024 112.563 17.5124H113.148Z" />
        <path d="M124.366 7.8374L126.841 17.0474H126.901L131.656 7.8374H133.606L132.226 19.1024H130.606L131.581 11.1524L127.696 18.6374C127.536 18.9474 127.286 19.1024 126.946 19.1024H126.331C125.981 19.1024 125.771 18.9474 125.701 18.6374L123.631 11.1524L122.656 19.1024H121.036L122.416 7.8374H124.366Z" />
        <path d="M55.6343 36.1624C54.3243 36.1624 53.2443 36.0974 52.3943 35.9674L52.6043 34.2874C53.8243 34.4374 54.8993 34.5124 55.8293 34.5124H56.0243C56.6543 34.5124 57.1493 34.4524 57.5093 34.3324C57.8793 34.2124 58.1443 34.0374 58.3043 33.8074C58.4643 33.5774 58.5643 33.2824 58.6043 32.9224C58.6243 32.7424 58.6343 32.6174 58.6343 32.5474C58.6343 32.1974 58.5543 31.9324 58.3943 31.7524C58.2343 31.5724 57.9943 31.4474 57.6743 31.3774C57.3643 31.2974 56.8943 31.2174 56.2643 31.1374C55.5443 31.0574 54.9743 30.9324 54.5543 30.7624C54.1343 30.5924 53.8193 30.3474 53.6093 30.0274C53.4093 29.6974 53.3093 29.2574 53.3093 28.7074C53.3093 28.4874 53.3243 28.2424 53.3543 27.9724C53.4843 26.8424 53.8643 26.0374 54.4943 25.5574C55.1243 25.0774 56.0893 24.8374 57.3893 24.8374H58.4393C59.1993 24.8374 60.0393 24.8474 60.9593 24.8674L60.7643 26.5174C60.2343 26.5174 59.8293 26.5124 59.5493 26.5024L58.2443 26.4874H57.1943C56.4643 26.4874 55.9293 26.6174 55.5893 26.8774C55.2593 27.1274 55.0943 27.6074 55.0943 28.3174C55.0943 28.6274 55.1543 28.8624 55.2743 29.0224C55.3943 29.1724 55.5593 29.2774 55.7693 29.3374C55.9793 29.3974 56.3193 29.4624 56.7893 29.5324C56.8793 29.5424 57.0593 29.5674 57.3293 29.6074C58.0693 29.7174 58.6593 29.8624 59.0993 30.0424C59.5393 30.2224 59.8693 30.4824 60.0893 30.8224C60.3093 31.1524 60.4193 31.6024 60.4193 32.1724C60.4193 32.3824 60.4043 32.6074 60.3743 32.8474C60.2743 33.6774 60.0543 34.3324 59.7143 34.8124C59.3843 35.2924 58.9043 35.6374 58.2743 35.8474C57.6443 36.0574 56.8293 36.1624 55.8293 36.1624H55.6343Z" />
        <path d="M64.6041 24.8374H71.8641L71.6691 26.4574H66.1791L65.7891 29.5924H70.5891L70.3941 31.1974H65.5941L65.1891 34.4674H70.8141L70.6191 36.1024H63.2241L64.6041 24.8374Z" />
        <path d="M80.1357 24.8374C81.1557 24.8474 81.9407 25.0874 82.4907 25.5574C83.0407 26.0174 83.3157 26.6874 83.3157 27.5674C83.3157 27.6774 83.3057 27.8524 83.2857 28.0924L83.2257 28.5874C83.1057 29.4974 82.7857 30.2174 82.2657 30.7474C81.7557 31.2774 81.0657 31.6124 80.1957 31.7524L83.2557 36.1024H81.0957L78.2457 31.8274H76.1907L75.6657 36.1024H73.8957L75.2757 24.8374H80.1357ZM79.5207 30.2074C80.0907 30.1974 80.5307 30.0774 80.8407 29.8474C81.1607 29.6174 81.3557 29.2224 81.4257 28.6624L81.5157 28.0174C81.5257 27.9474 81.5307 27.8524 81.5307 27.7324C81.5307 27.2824 81.4007 26.9624 81.1407 26.7724C80.8807 26.5724 80.4957 26.4674 79.9857 26.4574H76.8507L76.3857 30.2074H79.5207Z" />
        <path d="M89.2987 36.1024C88.7887 36.1024 88.4787 35.7824 88.3687 35.1424L86.6737 24.8374H88.5487L90.0487 34.4524H91.3087L95.1937 24.8374H97.1287L92.8687 35.2774C92.7487 35.5774 92.6037 35.7874 92.4337 35.9074C92.2637 36.0374 92.0587 36.1024 91.8187 36.1024H89.2987Z" />
        <path d="M100.032 24.8374H101.802L100.422 36.1024H98.6522L100.032 24.8374Z" />
        <path d="M110.362 24.8374C110.782 24.8374 111.382 24.8474 112.162 24.8674L113.092 24.8824L112.867 26.5474L111.997 26.5174C110.997 26.4974 110.372 26.4874 110.122 26.4874H109.462C108.552 26.4874 107.872 26.7174 107.422 27.1774C106.982 27.6374 106.692 28.3824 106.552 29.4124L106.282 31.5874C106.242 31.9874 106.222 32.2674 106.222 32.4274C106.222 33.1574 106.392 33.6874 106.732 34.0174C107.082 34.3474 107.652 34.5124 108.442 34.5124H109.162C109.692 34.5124 110.552 34.4774 111.742 34.4074L112.117 34.3924L111.997 36.0424C111.017 36.1224 110.022 36.1624 109.012 36.1624H108.292C106.972 36.1524 106.002 35.8674 105.382 35.3074C104.762 34.7474 104.452 33.8674 104.452 32.6674C104.452 32.3574 104.477 31.9674 104.527 31.4974L104.752 29.5024C104.962 27.8724 105.457 26.6924 106.237 25.9624C107.027 25.2224 108.182 24.8474 109.702 24.8374H110.362Z" />
        <path d="M116.717 24.8374H123.977L123.782 26.4574H118.292L117.902 29.5924H122.702L122.507 31.1974H117.707L117.302 34.4674H122.927L122.732 36.1024H115.337L116.717 24.8374Z" />
      </mask>
      <path
        d="M59.0993 7.8374C60.1393 7.8474 60.9393 8.0974 61.4993 8.5874C62.0693 9.0674 62.3543 9.7624 62.3543 10.6724C62.3543 10.9224 62.3443 11.1174 62.3243 11.2574L62.2343 12.0224C62.0943 13.1224 61.6743 13.9674 60.9743 14.5574C60.2743 15.1374 59.3393 15.4324 58.1693 15.4424H55.1093L54.6593 19.1024H52.8893L54.2693 7.8374H59.0993ZM58.4093 13.7924C59.0293 13.7824 59.5043 13.6474 59.8343 13.3874C60.1643 13.1174 60.3693 12.6924 60.4493 12.1124L60.5693 11.1974C60.5793 11.1274 60.5843 11.0324 60.5843 10.9124C60.5843 10.4224 60.4493 10.0674 60.1793 9.8474C59.9193 9.6174 59.5093 9.4974 58.9493 9.4874H55.8443L55.3043 13.7924H58.4093Z"
        fill="#304AB4"
      />
      <path
        d="M59.0993 7.8374C60.1393 7.8474 60.9393 8.0974 61.4993 8.5874C62.0693 9.0674 62.3543 9.7624 62.3543 10.6724C62.3543 10.9224 62.3443 11.1174 62.3243 11.2574L62.2343 12.0224C62.0943 13.1224 61.6743 13.9674 60.9743 14.5574C60.2743 15.1374 59.3393 15.4324 58.1693 15.4424H55.1093L54.6593 19.1024H52.8893L54.2693 7.8374H59.0993ZM58.4093 13.7924C59.0293 13.7824 59.5043 13.6474 59.8343 13.3874C60.1643 13.1174 60.3693 12.6924 60.4493 12.1124L60.5693 11.1974C60.5793 11.1274 60.5843 11.0324 60.5843 10.9124C60.5843 10.4224 60.4493 10.0674 60.1793 9.8474C59.9193 9.6174 59.5093 9.4974 58.9493 9.4874H55.8443L55.3043 13.7924H58.4093Z"
        fill="url(#paint4_linear_4865_36689)"
      />
      <path
        d="M70.8703 7.8374C71.8903 7.8474 72.6753 8.0874 73.2253 8.5574C73.7753 9.0174 74.0503 9.6874 74.0503 10.5674C74.0503 10.6774 74.0403 10.8524 74.0203 11.0924L73.9603 11.5874C73.8403 12.4974 73.5203 13.2174 73.0003 13.7474C72.4903 14.2774 71.8003 14.6124 70.9303 14.7524L73.9903 19.1024H71.8303L68.9803 14.8274H66.9253L66.4003 19.1024H64.6303L66.0103 7.8374H70.8703ZM70.2553 13.2074C70.8253 13.1974 71.2653 13.0774 71.5753 12.8474C71.8953 12.6174 72.0903 12.2224 72.1603 11.6624L72.2503 11.0174C72.2603 10.9474 72.2653 10.8524 72.2653 10.7324C72.2653 10.2824 72.1353 9.9624 71.8753 9.7724C71.6153 9.5724 71.2303 9.4674 70.7203 9.4574H67.5853L67.1203 13.2074H70.2553Z"
        fill="#304AB4"
      />
      <path
        d="M70.8703 7.8374C71.8903 7.8474 72.6753 8.0874 73.2253 8.5574C73.7753 9.0174 74.0503 9.6874 74.0503 10.5674C74.0503 10.6774 74.0403 10.8524 74.0203 11.0924L73.9603 11.5874C73.8403 12.4974 73.5203 13.2174 73.0003 13.7474C72.4903 14.2774 71.8003 14.6124 70.9303 14.7524L73.9903 19.1024H71.8303L68.9803 14.8274H66.9253L66.4003 19.1024H64.6303L66.0103 7.8374H70.8703ZM70.2553 13.2074C70.8253 13.1974 71.2653 13.0774 71.5753 12.8474C71.8953 12.6174 72.0903 12.2224 72.1603 11.6624L72.2503 11.0174C72.2603 10.9474 72.2653 10.8524 72.2653 10.7324C72.2653 10.2824 72.1353 9.9624 71.8753 9.7724C71.6153 9.5724 71.2303 9.4674 70.7203 9.4574H67.5853L67.1203 13.2074H70.2553Z"
        fill="url(#paint5_linear_4865_36689)"
      />
      <path
        d="M78.2933 7.8374H85.5533L85.3583 9.4574H79.8683L79.4783 12.5924H84.2783L84.0833 14.1974H79.2833L78.8783 17.4674H84.5033L84.3083 19.1024H76.9133L78.2933 7.8374Z"
        fill="#304AB4"
      />
      <path
        d="M78.2933 7.8374H85.5533L85.3583 9.4574H79.8683L79.4783 12.5924H84.2783L84.0833 14.1974H79.2833L78.8783 17.4674H84.5033L84.3083 19.1024H76.9133L78.2933 7.8374Z"
        fill="url(#paint6_linear_4865_36689)"
      />
      <path
        d="M90.915 7.8374L93.39 17.0474H93.45L98.205 7.8374H100.155L98.775 19.1024H97.155L98.13 11.1524L94.245 18.6374C94.085 18.9474 93.835 19.1024 93.495 19.1024H92.88C92.53 19.1024 92.32 18.9474 92.25 18.6374L90.18 11.1524L89.205 19.1024H87.585L88.965 7.8374H90.915Z"
        fill="#304AB4"
      />
      <path
        d="M90.915 7.8374L93.39 17.0474H93.45L98.205 7.8374H100.155L98.775 19.1024H97.155L98.13 11.1524L94.245 18.6374C94.085 18.9474 93.835 19.1024 93.495 19.1024H92.88C92.53 19.1024 92.32 18.9474 92.25 18.6374L90.18 11.1524L89.205 19.1024H87.585L88.965 7.8374H90.915Z"
        fill="url(#paint7_linear_4865_36689)"
      />
      <path
        d="M103.943 7.8374H105.713L104.333 19.1024H102.563L103.943 7.8374Z"
        fill="#304AB4"
      />
      <path
        d="M103.943 7.8374H105.713L104.333 19.1024H102.563L103.943 7.8374Z"
        fill="url(#paint8_linear_4865_36689)"
      />
      <path
        d="M113.148 17.5124C114.078 17.5024 114.768 17.2724 115.218 16.8224C115.668 16.3724 115.963 15.6424 116.103 14.6324L116.928 7.8374H118.698L117.888 14.5424C117.678 16.1524 117.183 17.3224 116.403 18.0524C115.623 18.7824 114.473 19.1524 112.953 19.1624H112.368C111.038 19.1524 110.063 18.8674 109.443 18.3074C108.823 17.7474 108.513 16.8674 108.513 15.6674C108.513 15.3674 108.538 14.9924 108.588 14.5424L109.413 7.8374H111.183L110.358 14.6324C110.318 15.0324 110.298 15.3024 110.298 15.4424C110.298 16.1724 110.473 16.6974 110.823 17.0174C111.173 17.3374 111.753 17.5024 112.563 17.5124H113.148Z"
        fill="#304AB4"
      />
      <path
        d="M113.148 17.5124C114.078 17.5024 114.768 17.2724 115.218 16.8224C115.668 16.3724 115.963 15.6424 116.103 14.6324L116.928 7.8374H118.698L117.888 14.5424C117.678 16.1524 117.183 17.3224 116.403 18.0524C115.623 18.7824 114.473 19.1524 112.953 19.1624H112.368C111.038 19.1524 110.063 18.8674 109.443 18.3074C108.823 17.7474 108.513 16.8674 108.513 15.6674C108.513 15.3674 108.538 14.9924 108.588 14.5424L109.413 7.8374H111.183L110.358 14.6324C110.318 15.0324 110.298 15.3024 110.298 15.4424C110.298 16.1724 110.473 16.6974 110.823 17.0174C111.173 17.3374 111.753 17.5024 112.563 17.5124H113.148Z"
        fill="url(#paint9_linear_4865_36689)"
      />
      <path
        d="M124.366 7.8374L126.841 17.0474H126.901L131.656 7.8374H133.606L132.226 19.1024H130.606L131.581 11.1524L127.696 18.6374C127.536 18.9474 127.286 19.1024 126.946 19.1024H126.331C125.981 19.1024 125.771 18.9474 125.701 18.6374L123.631 11.1524L122.656 19.1024H121.036L122.416 7.8374H124.366Z"
        fill="#304AB4"
      />
      <path
        d="M124.366 7.8374L126.841 17.0474H126.901L131.656 7.8374H133.606L132.226 19.1024H130.606L131.581 11.1524L127.696 18.6374C127.536 18.9474 127.286 19.1024 126.946 19.1024H126.331C125.981 19.1024 125.771 18.9474 125.701 18.6374L123.631 11.1524L122.656 19.1024H121.036L122.416 7.8374H124.366Z"
        fill="url(#paint10_linear_4865_36689)"
      />
      <path
        d="M55.6343 36.1624C54.3243 36.1624 53.2443 36.0974 52.3943 35.9674L52.6043 34.2874C53.8243 34.4374 54.8993 34.5124 55.8293 34.5124H56.0243C56.6543 34.5124 57.1493 34.4524 57.5093 34.3324C57.8793 34.2124 58.1443 34.0374 58.3043 33.8074C58.4643 33.5774 58.5643 33.2824 58.6043 32.9224C58.6243 32.7424 58.6343 32.6174 58.6343 32.5474C58.6343 32.1974 58.5543 31.9324 58.3943 31.7524C58.2343 31.5724 57.9943 31.4474 57.6743 31.3774C57.3643 31.2974 56.8943 31.2174 56.2643 31.1374C55.5443 31.0574 54.9743 30.9324 54.5543 30.7624C54.1343 30.5924 53.8193 30.3474 53.6093 30.0274C53.4093 29.6974 53.3093 29.2574 53.3093 28.7074C53.3093 28.4874 53.3243 28.2424 53.3543 27.9724C53.4843 26.8424 53.8643 26.0374 54.4943 25.5574C55.1243 25.0774 56.0893 24.8374 57.3893 24.8374H58.4393C59.1993 24.8374 60.0393 24.8474 60.9593 24.8674L60.7643 26.5174C60.2343 26.5174 59.8293 26.5124 59.5493 26.5024L58.2443 26.4874H57.1943C56.4643 26.4874 55.9293 26.6174 55.5893 26.8774C55.2593 27.1274 55.0943 27.6074 55.0943 28.3174C55.0943 28.6274 55.1543 28.8624 55.2743 29.0224C55.3943 29.1724 55.5593 29.2774 55.7693 29.3374C55.9793 29.3974 56.3193 29.4624 56.7893 29.5324C56.8793 29.5424 57.0593 29.5674 57.3293 29.6074C58.0693 29.7174 58.6593 29.8624 59.0993 30.0424C59.5393 30.2224 59.8693 30.4824 60.0893 30.8224C60.3093 31.1524 60.4193 31.6024 60.4193 32.1724C60.4193 32.3824 60.4043 32.6074 60.3743 32.8474C60.2743 33.6774 60.0543 34.3324 59.7143 34.8124C59.3843 35.2924 58.9043 35.6374 58.2743 35.8474C57.6443 36.0574 56.8293 36.1624 55.8293 36.1624H55.6343Z"
        fill="#304AB4"
      />
      <path
        d="M55.6343 36.1624C54.3243 36.1624 53.2443 36.0974 52.3943 35.9674L52.6043 34.2874C53.8243 34.4374 54.8993 34.5124 55.8293 34.5124H56.0243C56.6543 34.5124 57.1493 34.4524 57.5093 34.3324C57.8793 34.2124 58.1443 34.0374 58.3043 33.8074C58.4643 33.5774 58.5643 33.2824 58.6043 32.9224C58.6243 32.7424 58.6343 32.6174 58.6343 32.5474C58.6343 32.1974 58.5543 31.9324 58.3943 31.7524C58.2343 31.5724 57.9943 31.4474 57.6743 31.3774C57.3643 31.2974 56.8943 31.2174 56.2643 31.1374C55.5443 31.0574 54.9743 30.9324 54.5543 30.7624C54.1343 30.5924 53.8193 30.3474 53.6093 30.0274C53.4093 29.6974 53.3093 29.2574 53.3093 28.7074C53.3093 28.4874 53.3243 28.2424 53.3543 27.9724C53.4843 26.8424 53.8643 26.0374 54.4943 25.5574C55.1243 25.0774 56.0893 24.8374 57.3893 24.8374H58.4393C59.1993 24.8374 60.0393 24.8474 60.9593 24.8674L60.7643 26.5174C60.2343 26.5174 59.8293 26.5124 59.5493 26.5024L58.2443 26.4874H57.1943C56.4643 26.4874 55.9293 26.6174 55.5893 26.8774C55.2593 27.1274 55.0943 27.6074 55.0943 28.3174C55.0943 28.6274 55.1543 28.8624 55.2743 29.0224C55.3943 29.1724 55.5593 29.2774 55.7693 29.3374C55.9793 29.3974 56.3193 29.4624 56.7893 29.5324C56.8793 29.5424 57.0593 29.5674 57.3293 29.6074C58.0693 29.7174 58.6593 29.8624 59.0993 30.0424C59.5393 30.2224 59.8693 30.4824 60.0893 30.8224C60.3093 31.1524 60.4193 31.6024 60.4193 32.1724C60.4193 32.3824 60.4043 32.6074 60.3743 32.8474C60.2743 33.6774 60.0543 34.3324 59.7143 34.8124C59.3843 35.2924 58.9043 35.6374 58.2743 35.8474C57.6443 36.0574 56.8293 36.1624 55.8293 36.1624H55.6343Z"
        fill="url(#paint11_linear_4865_36689)"
      />
      <path
        d="M64.6041 24.8374H71.8641L71.6691 26.4574H66.1791L65.7891 29.5924H70.5891L70.3941 31.1974H65.5941L65.1891 34.4674H70.8141L70.6191 36.1024H63.2241L64.6041 24.8374Z"
        fill="#304AB4"
      />
      <path
        d="M64.6041 24.8374H71.8641L71.6691 26.4574H66.1791L65.7891 29.5924H70.5891L70.3941 31.1974H65.5941L65.1891 34.4674H70.8141L70.6191 36.1024H63.2241L64.6041 24.8374Z"
        fill="url(#paint12_linear_4865_36689)"
      />
      <path
        d="M80.1357 24.8374C81.1557 24.8474 81.9407 25.0874 82.4907 25.5574C83.0407 26.0174 83.3157 26.6874 83.3157 27.5674C83.3157 27.6774 83.3057 27.8524 83.2857 28.0924L83.2257 28.5874C83.1057 29.4974 82.7857 30.2174 82.2657 30.7474C81.7557 31.2774 81.0657 31.6124 80.1957 31.7524L83.2557 36.1024H81.0957L78.2457 31.8274H76.1907L75.6657 36.1024H73.8957L75.2757 24.8374H80.1357ZM79.5207 30.2074C80.0907 30.1974 80.5307 30.0774 80.8407 29.8474C81.1607 29.6174 81.3557 29.2224 81.4257 28.6624L81.5157 28.0174C81.5257 27.9474 81.5307 27.8524 81.5307 27.7324C81.5307 27.2824 81.4007 26.9624 81.1407 26.7724C80.8807 26.5724 80.4957 26.4674 79.9857 26.4574H76.8507L76.3857 30.2074H79.5207Z"
        fill="#304AB4"
      />
      <path
        d="M80.1357 24.8374C81.1557 24.8474 81.9407 25.0874 82.4907 25.5574C83.0407 26.0174 83.3157 26.6874 83.3157 27.5674C83.3157 27.6774 83.3057 27.8524 83.2857 28.0924L83.2257 28.5874C83.1057 29.4974 82.7857 30.2174 82.2657 30.7474C81.7557 31.2774 81.0657 31.6124 80.1957 31.7524L83.2557 36.1024H81.0957L78.2457 31.8274H76.1907L75.6657 36.1024H73.8957L75.2757 24.8374H80.1357ZM79.5207 30.2074C80.0907 30.1974 80.5307 30.0774 80.8407 29.8474C81.1607 29.6174 81.3557 29.2224 81.4257 28.6624L81.5157 28.0174C81.5257 27.9474 81.5307 27.8524 81.5307 27.7324C81.5307 27.2824 81.4007 26.9624 81.1407 26.7724C80.8807 26.5724 80.4957 26.4674 79.9857 26.4574H76.8507L76.3857 30.2074H79.5207Z"
        fill="url(#paint13_linear_4865_36689)"
      />
      <path
        d="M89.2987 36.1024C88.7887 36.1024 88.4787 35.7824 88.3687 35.1424L86.6737 24.8374H88.5487L90.0487 34.4524H91.3087L95.1937 24.8374H97.1287L92.8687 35.2774C92.7487 35.5774 92.6037 35.7874 92.4337 35.9074C92.2637 36.0374 92.0587 36.1024 91.8187 36.1024H89.2987Z"
        fill="#304AB4"
      />
      <path
        d="M89.2987 36.1024C88.7887 36.1024 88.4787 35.7824 88.3687 35.1424L86.6737 24.8374H88.5487L90.0487 34.4524H91.3087L95.1937 24.8374H97.1287L92.8687 35.2774C92.7487 35.5774 92.6037 35.7874 92.4337 35.9074C92.2637 36.0374 92.0587 36.1024 91.8187 36.1024H89.2987Z"
        fill="url(#paint14_linear_4865_36689)"
      />
      <path
        d="M100.032 24.8374H101.802L100.422 36.1024H98.6522L100.032 24.8374Z"
        fill="#304AB4"
      />
      <path
        d="M100.032 24.8374H101.802L100.422 36.1024H98.6522L100.032 24.8374Z"
        fill="url(#paint15_linear_4865_36689)"
      />
      <path
        d="M110.362 24.8374C110.782 24.8374 111.382 24.8474 112.162 24.8674L113.092 24.8824L112.867 26.5474L111.997 26.5174C110.997 26.4974 110.372 26.4874 110.122 26.4874H109.462C108.552 26.4874 107.872 26.7174 107.422 27.1774C106.982 27.6374 106.692 28.3824 106.552 29.4124L106.282 31.5874C106.242 31.9874 106.222 32.2674 106.222 32.4274C106.222 33.1574 106.392 33.6874 106.732 34.0174C107.082 34.3474 107.652 34.5124 108.442 34.5124H109.162C109.692 34.5124 110.552 34.4774 111.742 34.4074L112.117 34.3924L111.997 36.0424C111.017 36.1224 110.022 36.1624 109.012 36.1624H108.292C106.972 36.1524 106.002 35.8674 105.382 35.3074C104.762 34.7474 104.452 33.8674 104.452 32.6674C104.452 32.3574 104.477 31.9674 104.527 31.4974L104.752 29.5024C104.962 27.8724 105.457 26.6924 106.237 25.9624C107.027 25.2224 108.182 24.8474 109.702 24.8374H110.362Z"
        fill="#304AB4"
      />
      <path
        d="M110.362 24.8374C110.782 24.8374 111.382 24.8474 112.162 24.8674L113.092 24.8824L112.867 26.5474L111.997 26.5174C110.997 26.4974 110.372 26.4874 110.122 26.4874H109.462C108.552 26.4874 107.872 26.7174 107.422 27.1774C106.982 27.6374 106.692 28.3824 106.552 29.4124L106.282 31.5874C106.242 31.9874 106.222 32.2674 106.222 32.4274C106.222 33.1574 106.392 33.6874 106.732 34.0174C107.082 34.3474 107.652 34.5124 108.442 34.5124H109.162C109.692 34.5124 110.552 34.4774 111.742 34.4074L112.117 34.3924L111.997 36.0424C111.017 36.1224 110.022 36.1624 109.012 36.1624H108.292C106.972 36.1524 106.002 35.8674 105.382 35.3074C104.762 34.7474 104.452 33.8674 104.452 32.6674C104.452 32.3574 104.477 31.9674 104.527 31.4974L104.752 29.5024C104.962 27.8724 105.457 26.6924 106.237 25.9624C107.027 25.2224 108.182 24.8474 109.702 24.8374H110.362Z"
        fill="url(#paint16_linear_4865_36689)"
      />
      <path
        d="M116.717 24.8374H123.977L123.782 26.4574H118.292L117.902 29.5924H122.702L122.507 31.1974H117.707L117.302 34.4674H122.927L122.732 36.1024H115.337L116.717 24.8374Z"
        fill="#304AB4"
      />
      <path
        d="M116.717 24.8374H123.977L123.782 26.4574H118.292L117.902 29.5924H122.702L122.507 31.1974H117.707L117.302 34.4674H122.927L122.732 36.1024H115.337L116.717 24.8374Z"
        fill="url(#paint17_linear_4865_36689)"
      />
      <path
        d="M59.0993 7.8374C60.1393 7.8474 60.9393 8.0974 61.4993 8.5874C62.0693 9.0674 62.3543 9.7624 62.3543 10.6724C62.3543 10.9224 62.3443 11.1174 62.3243 11.2574L62.2343 12.0224C62.0943 13.1224 61.6743 13.9674 60.9743 14.5574C60.2743 15.1374 59.3393 15.4324 58.1693 15.4424H55.1093L54.6593 19.1024H52.8893L54.2693 7.8374H59.0993ZM58.4093 13.7924C59.0293 13.7824 59.5043 13.6474 59.8343 13.3874C60.1643 13.1174 60.3693 12.6924 60.4493 12.1124L60.5693 11.1974C60.5793 11.1274 60.5843 11.0324 60.5843 10.9124C60.5843 10.4224 60.4493 10.0674 60.1793 9.8474C59.9193 9.6174 59.5093 9.4974 58.9493 9.4874H55.8443L55.3043 13.7924H58.4093Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M59.0993 7.8374C60.1393 7.8474 60.9393 8.0974 61.4993 8.5874C62.0693 9.0674 62.3543 9.7624 62.3543 10.6724C62.3543 10.9224 62.3443 11.1174 62.3243 11.2574L62.2343 12.0224C62.0943 13.1224 61.6743 13.9674 60.9743 14.5574C60.2743 15.1374 59.3393 15.4324 58.1693 15.4424H55.1093L54.6593 19.1024H52.8893L54.2693 7.8374H59.0993ZM58.4093 13.7924C59.0293 13.7824 59.5043 13.6474 59.8343 13.3874C60.1643 13.1174 60.3693 12.6924 60.4493 12.1124L60.5693 11.1974C60.5793 11.1274 60.5843 11.0324 60.5843 10.9124C60.5843 10.4224 60.4493 10.0674 60.1793 9.8474C59.9193 9.6174 59.5093 9.4974 58.9493 9.4874H55.8443L55.3043 13.7924H58.4093Z"
        stroke="url(#paint18_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M70.8703 7.8374C71.8903 7.8474 72.6753 8.0874 73.2253 8.5574C73.7753 9.0174 74.0503 9.6874 74.0503 10.5674C74.0503 10.6774 74.0403 10.8524 74.0203 11.0924L73.9603 11.5874C73.8403 12.4974 73.5203 13.2174 73.0003 13.7474C72.4903 14.2774 71.8003 14.6124 70.9303 14.7524L73.9903 19.1024H71.8303L68.9803 14.8274H66.9253L66.4003 19.1024H64.6303L66.0103 7.8374H70.8703ZM70.2553 13.2074C70.8253 13.1974 71.2653 13.0774 71.5753 12.8474C71.8953 12.6174 72.0903 12.2224 72.1603 11.6624L72.2503 11.0174C72.2603 10.9474 72.2653 10.8524 72.2653 10.7324C72.2653 10.2824 72.1353 9.9624 71.8753 9.7724C71.6153 9.5724 71.2303 9.4674 70.7203 9.4574H67.5853L67.1203 13.2074H70.2553Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M70.8703 7.8374C71.8903 7.8474 72.6753 8.0874 73.2253 8.5574C73.7753 9.0174 74.0503 9.6874 74.0503 10.5674C74.0503 10.6774 74.0403 10.8524 74.0203 11.0924L73.9603 11.5874C73.8403 12.4974 73.5203 13.2174 73.0003 13.7474C72.4903 14.2774 71.8003 14.6124 70.9303 14.7524L73.9903 19.1024H71.8303L68.9803 14.8274H66.9253L66.4003 19.1024H64.6303L66.0103 7.8374H70.8703ZM70.2553 13.2074C70.8253 13.1974 71.2653 13.0774 71.5753 12.8474C71.8953 12.6174 72.0903 12.2224 72.1603 11.6624L72.2503 11.0174C72.2603 10.9474 72.2653 10.8524 72.2653 10.7324C72.2653 10.2824 72.1353 9.9624 71.8753 9.7724C71.6153 9.5724 71.2303 9.4674 70.7203 9.4574H67.5853L67.1203 13.2074H70.2553Z"
        stroke="url(#paint19_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M78.2933 7.8374H85.5533L85.3583 9.4574H79.8683L79.4783 12.5924H84.2783L84.0833 14.1974H79.2833L78.8783 17.4674H84.5033L84.3083 19.1024H76.9133L78.2933 7.8374Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M78.2933 7.8374H85.5533L85.3583 9.4574H79.8683L79.4783 12.5924H84.2783L84.0833 14.1974H79.2833L78.8783 17.4674H84.5033L84.3083 19.1024H76.9133L78.2933 7.8374Z"
        stroke="url(#paint20_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M90.915 7.8374L93.39 17.0474H93.45L98.205 7.8374H100.155L98.775 19.1024H97.155L98.13 11.1524L94.245 18.6374C94.085 18.9474 93.835 19.1024 93.495 19.1024H92.88C92.53 19.1024 92.32 18.9474 92.25 18.6374L90.18 11.1524L89.205 19.1024H87.585L88.965 7.8374H90.915Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M90.915 7.8374L93.39 17.0474H93.45L98.205 7.8374H100.155L98.775 19.1024H97.155L98.13 11.1524L94.245 18.6374C94.085 18.9474 93.835 19.1024 93.495 19.1024H92.88C92.53 19.1024 92.32 18.9474 92.25 18.6374L90.18 11.1524L89.205 19.1024H87.585L88.965 7.8374H90.915Z"
        stroke="url(#paint21_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M103.943 7.8374H105.713L104.333 19.1024H102.563L103.943 7.8374Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M103.943 7.8374H105.713L104.333 19.1024H102.563L103.943 7.8374Z"
        stroke="url(#paint22_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M113.148 17.5124C114.078 17.5024 114.768 17.2724 115.218 16.8224C115.668 16.3724 115.963 15.6424 116.103 14.6324L116.928 7.8374H118.698L117.888 14.5424C117.678 16.1524 117.183 17.3224 116.403 18.0524C115.623 18.7824 114.473 19.1524 112.953 19.1624H112.368C111.038 19.1524 110.063 18.8674 109.443 18.3074C108.823 17.7474 108.513 16.8674 108.513 15.6674C108.513 15.3674 108.538 14.9924 108.588 14.5424L109.413 7.8374H111.183L110.358 14.6324C110.318 15.0324 110.298 15.3024 110.298 15.4424C110.298 16.1724 110.473 16.6974 110.823 17.0174C111.173 17.3374 111.753 17.5024 112.563 17.5124H113.148Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M113.148 17.5124C114.078 17.5024 114.768 17.2724 115.218 16.8224C115.668 16.3724 115.963 15.6424 116.103 14.6324L116.928 7.8374H118.698L117.888 14.5424C117.678 16.1524 117.183 17.3224 116.403 18.0524C115.623 18.7824 114.473 19.1524 112.953 19.1624H112.368C111.038 19.1524 110.063 18.8674 109.443 18.3074C108.823 17.7474 108.513 16.8674 108.513 15.6674C108.513 15.3674 108.538 14.9924 108.588 14.5424L109.413 7.8374H111.183L110.358 14.6324C110.318 15.0324 110.298 15.3024 110.298 15.4424C110.298 16.1724 110.473 16.6974 110.823 17.0174C111.173 17.3374 111.753 17.5024 112.563 17.5124H113.148Z"
        stroke="url(#paint23_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M124.366 7.8374L126.841 17.0474H126.901L131.656 7.8374H133.606L132.226 19.1024H130.606L131.581 11.1524L127.696 18.6374C127.536 18.9474 127.286 19.1024 126.946 19.1024H126.331C125.981 19.1024 125.771 18.9474 125.701 18.6374L123.631 11.1524L122.656 19.1024H121.036L122.416 7.8374H124.366Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M124.366 7.8374L126.841 17.0474H126.901L131.656 7.8374H133.606L132.226 19.1024H130.606L131.581 11.1524L127.696 18.6374C127.536 18.9474 127.286 19.1024 126.946 19.1024H126.331C125.981 19.1024 125.771 18.9474 125.701 18.6374L123.631 11.1524L122.656 19.1024H121.036L122.416 7.8374H124.366Z"
        stroke="url(#paint24_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M55.6343 36.1624C54.3243 36.1624 53.2443 36.0974 52.3943 35.9674L52.6043 34.2874C53.8243 34.4374 54.8993 34.5124 55.8293 34.5124H56.0243C56.6543 34.5124 57.1493 34.4524 57.5093 34.3324C57.8793 34.2124 58.1443 34.0374 58.3043 33.8074C58.4643 33.5774 58.5643 33.2824 58.6043 32.9224C58.6243 32.7424 58.6343 32.6174 58.6343 32.5474C58.6343 32.1974 58.5543 31.9324 58.3943 31.7524C58.2343 31.5724 57.9943 31.4474 57.6743 31.3774C57.3643 31.2974 56.8943 31.2174 56.2643 31.1374C55.5443 31.0574 54.9743 30.9324 54.5543 30.7624C54.1343 30.5924 53.8193 30.3474 53.6093 30.0274C53.4093 29.6974 53.3093 29.2574 53.3093 28.7074C53.3093 28.4874 53.3243 28.2424 53.3543 27.9724C53.4843 26.8424 53.8643 26.0374 54.4943 25.5574C55.1243 25.0774 56.0893 24.8374 57.3893 24.8374H58.4393C59.1993 24.8374 60.0393 24.8474 60.9593 24.8674L60.7643 26.5174C60.2343 26.5174 59.8293 26.5124 59.5493 26.5024L58.2443 26.4874H57.1943C56.4643 26.4874 55.9293 26.6174 55.5893 26.8774C55.2593 27.1274 55.0943 27.6074 55.0943 28.3174C55.0943 28.6274 55.1543 28.8624 55.2743 29.0224C55.3943 29.1724 55.5593 29.2774 55.7693 29.3374C55.9793 29.3974 56.3193 29.4624 56.7893 29.5324C56.8793 29.5424 57.0593 29.5674 57.3293 29.6074C58.0693 29.7174 58.6593 29.8624 59.0993 30.0424C59.5393 30.2224 59.8693 30.4824 60.0893 30.8224C60.3093 31.1524 60.4193 31.6024 60.4193 32.1724C60.4193 32.3824 60.4043 32.6074 60.3743 32.8474C60.2743 33.6774 60.0543 34.3324 59.7143 34.8124C59.3843 35.2924 58.9043 35.6374 58.2743 35.8474C57.6443 36.0574 56.8293 36.1624 55.8293 36.1624H55.6343Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M55.6343 36.1624C54.3243 36.1624 53.2443 36.0974 52.3943 35.9674L52.6043 34.2874C53.8243 34.4374 54.8993 34.5124 55.8293 34.5124H56.0243C56.6543 34.5124 57.1493 34.4524 57.5093 34.3324C57.8793 34.2124 58.1443 34.0374 58.3043 33.8074C58.4643 33.5774 58.5643 33.2824 58.6043 32.9224C58.6243 32.7424 58.6343 32.6174 58.6343 32.5474C58.6343 32.1974 58.5543 31.9324 58.3943 31.7524C58.2343 31.5724 57.9943 31.4474 57.6743 31.3774C57.3643 31.2974 56.8943 31.2174 56.2643 31.1374C55.5443 31.0574 54.9743 30.9324 54.5543 30.7624C54.1343 30.5924 53.8193 30.3474 53.6093 30.0274C53.4093 29.6974 53.3093 29.2574 53.3093 28.7074C53.3093 28.4874 53.3243 28.2424 53.3543 27.9724C53.4843 26.8424 53.8643 26.0374 54.4943 25.5574C55.1243 25.0774 56.0893 24.8374 57.3893 24.8374H58.4393C59.1993 24.8374 60.0393 24.8474 60.9593 24.8674L60.7643 26.5174C60.2343 26.5174 59.8293 26.5124 59.5493 26.5024L58.2443 26.4874H57.1943C56.4643 26.4874 55.9293 26.6174 55.5893 26.8774C55.2593 27.1274 55.0943 27.6074 55.0943 28.3174C55.0943 28.6274 55.1543 28.8624 55.2743 29.0224C55.3943 29.1724 55.5593 29.2774 55.7693 29.3374C55.9793 29.3974 56.3193 29.4624 56.7893 29.5324C56.8793 29.5424 57.0593 29.5674 57.3293 29.6074C58.0693 29.7174 58.6593 29.8624 59.0993 30.0424C59.5393 30.2224 59.8693 30.4824 60.0893 30.8224C60.3093 31.1524 60.4193 31.6024 60.4193 32.1724C60.4193 32.3824 60.4043 32.6074 60.3743 32.8474C60.2743 33.6774 60.0543 34.3324 59.7143 34.8124C59.3843 35.2924 58.9043 35.6374 58.2743 35.8474C57.6443 36.0574 56.8293 36.1624 55.8293 36.1624H55.6343Z"
        stroke="url(#paint25_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M64.6041 24.8374H71.8641L71.6691 26.4574H66.1791L65.7891 29.5924H70.5891L70.3941 31.1974H65.5941L65.1891 34.4674H70.8141L70.6191 36.1024H63.2241L64.6041 24.8374Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M64.6041 24.8374H71.8641L71.6691 26.4574H66.1791L65.7891 29.5924H70.5891L70.3941 31.1974H65.5941L65.1891 34.4674H70.8141L70.6191 36.1024H63.2241L64.6041 24.8374Z"
        stroke="url(#paint26_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M80.1357 24.8374C81.1557 24.8474 81.9407 25.0874 82.4907 25.5574C83.0407 26.0174 83.3157 26.6874 83.3157 27.5674C83.3157 27.6774 83.3057 27.8524 83.2857 28.0924L83.2257 28.5874C83.1057 29.4974 82.7857 30.2174 82.2657 30.7474C81.7557 31.2774 81.0657 31.6124 80.1957 31.7524L83.2557 36.1024H81.0957L78.2457 31.8274H76.1907L75.6657 36.1024H73.8957L75.2757 24.8374H80.1357ZM79.5207 30.2074C80.0907 30.1974 80.5307 30.0774 80.8407 29.8474C81.1607 29.6174 81.3557 29.2224 81.4257 28.6624L81.5157 28.0174C81.5257 27.9474 81.5307 27.8524 81.5307 27.7324C81.5307 27.2824 81.4007 26.9624 81.1407 26.7724C80.8807 26.5724 80.4957 26.4674 79.9857 26.4574H76.8507L76.3857 30.2074H79.5207Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M80.1357 24.8374C81.1557 24.8474 81.9407 25.0874 82.4907 25.5574C83.0407 26.0174 83.3157 26.6874 83.3157 27.5674C83.3157 27.6774 83.3057 27.8524 83.2857 28.0924L83.2257 28.5874C83.1057 29.4974 82.7857 30.2174 82.2657 30.7474C81.7557 31.2774 81.0657 31.6124 80.1957 31.7524L83.2557 36.1024H81.0957L78.2457 31.8274H76.1907L75.6657 36.1024H73.8957L75.2757 24.8374H80.1357ZM79.5207 30.2074C80.0907 30.1974 80.5307 30.0774 80.8407 29.8474C81.1607 29.6174 81.3557 29.2224 81.4257 28.6624L81.5157 28.0174C81.5257 27.9474 81.5307 27.8524 81.5307 27.7324C81.5307 27.2824 81.4007 26.9624 81.1407 26.7724C80.8807 26.5724 80.4957 26.4674 79.9857 26.4574H76.8507L76.3857 30.2074H79.5207Z"
        stroke="url(#paint27_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M89.2987 36.1024C88.7887 36.1024 88.4787 35.7824 88.3687 35.1424L86.6737 24.8374H88.5487L90.0487 34.4524H91.3087L95.1937 24.8374H97.1287L92.8687 35.2774C92.7487 35.5774 92.6037 35.7874 92.4337 35.9074C92.2637 36.0374 92.0587 36.1024 91.8187 36.1024H89.2987Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M89.2987 36.1024C88.7887 36.1024 88.4787 35.7824 88.3687 35.1424L86.6737 24.8374H88.5487L90.0487 34.4524H91.3087L95.1937 24.8374H97.1287L92.8687 35.2774C92.7487 35.5774 92.6037 35.7874 92.4337 35.9074C92.2637 36.0374 92.0587 36.1024 91.8187 36.1024H89.2987Z"
        stroke="url(#paint28_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M100.032 24.8374H101.802L100.422 36.1024H98.6522L100.032 24.8374Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M100.032 24.8374H101.802L100.422 36.1024H98.6522L100.032 24.8374Z"
        stroke="url(#paint29_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M110.362 24.8374C110.782 24.8374 111.382 24.8474 112.162 24.8674L113.092 24.8824L112.867 26.5474L111.997 26.5174C110.997 26.4974 110.372 26.4874 110.122 26.4874H109.462C108.552 26.4874 107.872 26.7174 107.422 27.1774C106.982 27.6374 106.692 28.3824 106.552 29.4124L106.282 31.5874C106.242 31.9874 106.222 32.2674 106.222 32.4274C106.222 33.1574 106.392 33.6874 106.732 34.0174C107.082 34.3474 107.652 34.5124 108.442 34.5124H109.162C109.692 34.5124 110.552 34.4774 111.742 34.4074L112.117 34.3924L111.997 36.0424C111.017 36.1224 110.022 36.1624 109.012 36.1624H108.292C106.972 36.1524 106.002 35.8674 105.382 35.3074C104.762 34.7474 104.452 33.8674 104.452 32.6674C104.452 32.3574 104.477 31.9674 104.527 31.4974L104.752 29.5024C104.962 27.8724 105.457 26.6924 106.237 25.9624C107.027 25.2224 108.182 24.8474 109.702 24.8374H110.362Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M110.362 24.8374C110.782 24.8374 111.382 24.8474 112.162 24.8674L113.092 24.8824L112.867 26.5474L111.997 26.5174C110.997 26.4974 110.372 26.4874 110.122 26.4874H109.462C108.552 26.4874 107.872 26.7174 107.422 27.1774C106.982 27.6374 106.692 28.3824 106.552 29.4124L106.282 31.5874C106.242 31.9874 106.222 32.2674 106.222 32.4274C106.222 33.1574 106.392 33.6874 106.732 34.0174C107.082 34.3474 107.652 34.5124 108.442 34.5124H109.162C109.692 34.5124 110.552 34.4774 111.742 34.4074L112.117 34.3924L111.997 36.0424C111.017 36.1224 110.022 36.1624 109.012 36.1624H108.292C106.972 36.1524 106.002 35.8674 105.382 35.3074C104.762 34.7474 104.452 33.8674 104.452 32.6674C104.452 32.3574 104.477 31.9674 104.527 31.4974L104.752 29.5024C104.962 27.8724 105.457 26.6924 106.237 25.9624C107.027 25.2224 108.182 24.8474 109.702 24.8374H110.362Z"
        stroke="url(#paint30_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M116.717 24.8374H123.977L123.782 26.4574H118.292L117.902 29.5924H122.702L122.507 31.1974H117.707L117.302 34.4674H122.927L122.732 36.1024H115.337L116.717 24.8374Z"
        stroke="#304AB4"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <path
        d="M116.717 24.8374H123.977L123.782 26.4574H118.292L117.902 29.5924H122.702L122.507 31.1974H117.707L117.302 34.4674H122.927L122.732 36.1024H115.337L116.717 24.8374Z"
        stroke="url(#paint31_linear_4865_36689)"
        mask="url(#path-233-outside-1_4865_36689)"
      />
      <defs>
        <filter
          id="filter0_d_4865_36689"
          x="3.40906"
          y="3.82959"
          width="37.9702"
          height="37.9704"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.814815" />
          <feGaussianBlur stdDeviation="0.407407" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.391355 0 0 0 0 0.558333 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4865_36689"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4865_36689"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4865_36689"
          x1="22.3943"
          y1="0"
          x2="22.3943"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FDFEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4865_36689"
          x1="22.3941"
          y1="22"
          x2="22.3941"
          y2="34.2222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0096D6" stopOpacity="0.4" />
          <stop offset="0.609784" stopColor="#0096D6" stopOpacity="0.06" />
          <stop offset="1" stopColor="#0096D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4865_36689"
          x1="-21.6057"
          y1="-35.4447"
          x2="33.3942"
          y2="52.1479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A2D7D" />
          <stop offset="0.598065" stopColor="#6271FF" />
          <stop offset="1" stopColor="#5BD3FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4865_36689"
          x1="2.92017"
          y1="21.9998"
          x2="41.8683"
          y2="21.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#646DFF" />
          <stop offset="0.594082" stopColor="#5BD0FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_4865_36689"
          x1="44.1715"
          y1="41.9941"
          x2="136.632"
          y2="35.9119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2351DF" />
          <stop offset="1" stopColor="#229BFF" />
        </linearGradient>
      </defs>
    </svg>
  )
);

export default SPLogo;
