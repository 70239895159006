/** @format */

export const PROJECTS = "client/PROJECTS";
export const namespace = "projects";

export type projectsType = {
  email: string;
};

export type projectsActions = {
  type: typeof PROJECTS;
  payload: projectsType;
};

export const initialState = {
  email: "",
};

export const configs = [
  {
    type: PROJECTS,
    state: (state: any, action: any) => ({
      ...state,
      projects: { email: action.payload.email },
    }),
  },
];

const projects = {
  namespace,
  initialState,
  configs,
};

export default projects;
