/** @format */

import React, { FC, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import * as R from "ramda";
import { Spin } from "antd";

const StyledContainer = styled.div`
  @keyframes circle {
    from {
      stroke-dashoffset: 1194;
    }
    to {
      stroke-dashoffset: 2388;
    }
  }

  @keyframes tick {
    from {
      stroke-dashoffset: 350;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  transform: scale(0.15);
  transform-origin: top;
  position: absolute;
  top: 80px;
  margin: 0;
  padding: 0;
  z-index: 1;
  .circle {
    stroke-dasharray: 1194;
    stroke-dashoffset: 1194;
    animation: circle 1s ease-in-out;
    animation-fill-mode: forwards;
    display: none;
  }

  .tick {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: tick 0.8s ease-out;
    animation-fill-mode: forwards;
    // animation-delay: 0.93s;
  }
`;

const StyledSvg = styled.svg``;

interface LoadingProps {
  isStart?: boolean;
  setInit?: (i: any) => void;
}

const CheckAnimation: FC<LoadingProps> = ({
  isStart = false,
  setInit = () => {},
}) => {
  const [showCheck, toggleCheck] = useState(false);

  useEffect(() => {
    if (showCheck) {
      setTimeout(() => {
        toggleCheck(false);
        setInit(false);
      }, 1000);
    }
  }, [showCheck]);

  useEffect(() => {
    if (isStart) {
      toggleCheck(true);
    }
  }, [isStart]);

  return (
    <StyledContainer>
      {showCheck && (
        <StyledSvg width="400" height="400">
          <circle
            fill="none"
            stroke="#68E534"
            strokeWidth="20"
            strokeLinecap="round"
            cx="200"
            cy="200"
            r="190"
            className="circle"
            transform="rotate(-90 200 200)"
          />

          <polyline
            fill="none"
            stroke="#68E534"
            strokeWidth="24"
            points="88,214 173,284 304,138"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="tick"
          />
        </StyledSvg>
      )}
    </StyledContainer>
  );
};

export default CheckAnimation;
