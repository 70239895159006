/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import { ChildProjectName } from "./interface";

import { routerName } from "constants/env";

export const childProjectName: ChildProjectName = {
  console: "Developer Portal",
  insights: "Insights",
};
export const solutionPath: { console: string; insights: string } = {
  console: "console",
  insights: routerName,
};
