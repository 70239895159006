/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const Logout = forwardRef(
  ({ width = 16, height = 16, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M5.71418 2.28564H3.42847V13.7142H5.71418"
        stroke="#2E2E2E"
        strokeWidth="1.14286"
        strokeLinecap="round"
      />
      <path
        d="M11.4282 4.57129L13.7139 7.99986L11.4282 11.4284"
        stroke="#2E2E2E"
        strokeWidth="1.14286"
        strokeLinecap="round"
      />
      <path
        d="M6.85693 8L12.5712 8"
        stroke="#2E2E2E"
        strokeWidth="1.14286"
        strokeLinecap="round"
      />
    </svg>
  )
);

export default Logout;
