/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import { LooseStaticObject } from "constants/interface";
import qs from "qs";
import { tokenManager } from "token";
import {
  keysToCamel,
  keysToSnack,
  camelToSnack,
  objToQuery,
} from "utils/stringUtil";

export const requestOptionsCreator =
  (getToken: any) =>
  (method: string, body: any, asForm: boolean, pub: string) => {
    let headers: LooseStaticObject = {
      // 'content-type': 'application/x-www-form-urlencoded; charset=utf-8', // 'application/json',
      "content-type": asForm
        ? "application/x-www-form-urlencoded; charset=utf-8"
        : "application/json",
    };
    if (!pub) {
      headers = { ...headers, authorization: `Bearer ${getToken()}` };
    }
    let bodyData = null;
    if (body) {
      bodyData = asForm ? qs.stringify(body) : JSON.stringify(body);
    }
    return {
      method,
      body: bodyData,
      headers,
    };
  };

export const requestCreator =
  (apiBase: string, requestOptions: any, store: any) =>
  async (
    {
      url,
      queryParams,
      body,
      method,
      asForm,
    }: {
      url: string;
      queryParams: any;
      body: any;
      method: string;
      asForm: boolean;
    },
    pub: string
  ) => {
    const options = requestOptions(
      method || "GET",
      keysToSnack(body),
      asForm,
      pub
    );
    // options.credentials = 'include'
    const query =
      queryParams && Object.keys(queryParams).length > 0
        ? `?${objToQuery(camelToSnack, queryParams)}`
        : "";

    try {
      const resp = await fetch(
        url.startsWith("http") ? `${url}${query}` : `${apiBase}${url}${query}`,
        options
      );

      let data = {};
      try {
        data = keysToCamel(await resp.text());
      } catch (e) {
        // just ignore this error
      }
      if (resp.status >= 400) {
        if (resp.status === 401) {
          tokenManager.logout();
        }
        throw data;
      }
      return data;
    } catch (error) {
      throw keysToCamel(error);
    }
  };
