/** @format */

import * as R from "ramda";

export const buildConfigs = (configs: any) => {
  return R.unnest(R.map((i) => i.configs, configs));
};

const getStorage = (persists: [], value: any) => {
  let initialState = value.initialState;
  let persistConsole: any = localStorage.getItem("persist:console");
  persistConsole = persistConsole ? JSON.parse(persistConsole) : {};
  R.map((i: any) => {
    if (i.namespace === value.namespace) {
      initialState = persistConsole[value.namespace]
        ? JSON.parse(persistConsole[value.namespace])
        : {};
    }
    return "";
  }, persists);

  return initialState;
};

function buildStore({ stores, persists }: { stores: any; persists: any }) {
  return R.mapObjIndexed(
    (value: any, key: string) => getStorage(persists, value),
    stores
  );
}

export default buildStore;
