/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Button, Switch, Radio } from "antd";
import { TranslationOutlined } from "@ant-design/icons";
import actions from "components/shared/actions";
import Confirm from "components/shared/Confirm";
import * as env from "constants/env";

let eqrcodeVersion = env.eqrcodeVersion === "true";

const primaryBtnStyle = {
  //   backgroundColor: "#229BFF",
  //   borderColor: "#229BFF",
  color: "#ffffff",
  minWidth: "76px",
  fontSize: "14px",
  borderRadius: "4px",
};

const cancelBtnStyle = {
  backgroundColor: "#E5E6EB",
  borderColor: "#E5E6EB",
  color: "#000000",
  width: "76px",
  fontSize: "14px",
  borderRadius: "4px",
};

const Container = styled.div`
  overflow-y: auto;
  max-height: 464px;
  padding: 20px 24px 10px 24px;
  text-align: center;
`;

const StyledTitle = styled.div`
  margin-top: 12px;
  margin-left: 1px;
  padding-top: 2px;
`;

interface PropTypes {
  title: string;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
  isVisible: boolean;
}

const LogoutDlg = ({
  title,
  isLoading,
  onClose,
  isVisible,
  onOk,
  ...resProps
}: PropTypes) => {
  const { t } = useTranslation();

  const locale = localStorage.getItem("locale");

  const buttons = (
    <>
      <Button
        onClick={() => {
          onClose();
        }}
        style={cancelBtnStyle}
      >
        {t("button.cancel")}
      </Button>
    </>
  );

  return (
    <Confirm
      title={
        <StyledTitle>
          <TranslationOutlined
            rev="true"
            style={{ verticalAlign: "-2px", marginRight: "10px" }}
          />
          {title}
        </StyledTitle>
      }
      onClose={() => {
        onClose();
      }}
      closable={false}
      buttons={buttons}
      width={390}
      bodyStyle={{ padding: "0" }}
      maskStyle={{}}
      isVisible={isVisible}
      wrapClassName="logout-dialog-wrap"
      {...resProps}
    >
      <Container>
        <Radio.Group
          defaultValue={locale || "en"}
          buttonStyle="solid"
          onChange={(e) => {
            const value = e.target.value;
            localStorage.setItem("locale", value);
            i18n.changeLanguage(value);
            // actions.setGlobalState({ onLanguage: value });
            window.location.reload();
          }}
        >
          <Radio.Button value="default">
            {t("topBar.rightDropdown.default")}
          </Radio.Button>
          <Radio.Button value="en">English</Radio.Button>
          <Radio.Button value="zh-CN">简体中文</Radio.Button>
        </Radio.Group>
      </Container>
    </Confirm>
  );
};

export default LogoutDlg;
