/** @format */

import * as R from "ramda";

import en from "locales/en/en.json";
import zh from "locales/zh/zh.json";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";

export const resources = {
  en: {
    translation: en,
  },
  "zh-CN": {
    translation: zh,
  },
} as const;

export const getDefaultLanguage = () => {
  const userLanguage =
    R.path(["language"], navigator) || R.path(["userLanguage"], navigator);

  const locals: any = {
    en: "en",
    "zh-CN": "zh-CN",
  };

  let local = localStorage.getItem("locale");

  if (!local) {
    local = "en";
  }

  if (local === "default") {
    local = userLanguage === "zh-CN" ? userLanguage : "en";
  }

  // local = local.toLowerCase()
  let language = "en";
  if (locals[local]) {
    language = locals[local];
  } else {
    const contry = local.split("-")[0];
    if (locals[contry]) {
      language = locals[contry];
    }
  }
  return language;
};

const languageDetector: any = {
  type: "languageDetector",
  async: true,
  detect: (cb: any) => cb(getDefaultLanguage()),
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n.use(languageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  resources,
});
