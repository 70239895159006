/** @format */

import React, { useReducer, useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyleSheetManager } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import UserContext from "store/createContext";
import initState, { userReducer } from "store/initialState";

import * as api from "authApi";
import Root from "pages";
import { apiBase } from "constants/env";
import { ConfigProvider } from "antd";

import { encrypt, decrypt } from "utils/crypto";

function App() {
  const [state, dispatch] = useReducer(userReducer, initState);

  api.init(state, apiBase);

  const queryClient = new QueryClient();

  // encrypt(
  //   "eyJhbGciOiJSUzI1NiIsImtpZCI6IjYyZDIxMGU1LTc3NWMtNGM1ZC05ZDY1LWNhOWRkYzA5MWU0YyIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiaHR0cHM6Ly9ocGJwLWRldi5ocGJwLmlvIiwiaHR0cDovL2xvY2FsaG9zdDozMDAwIl0sImNsaWVudF9pZCI6IjI4ZjZlYzBlLTIwOWMtNDVmNC1hYTE5LWM5YjU2NDNmYjE1YyIsImVudmlyb25tZW50X2lkIjoiZjRiZmFlNzctZmVmMi00ZTVmLWJmMjktNzU5NWExZjk3NjZkIiwiZXhwIjoxNjk3MDE3NzQxLCJpYXQiOjE2OTcwMTQxNDEsImlkcCI6ImhwaWRfc3RnIiwiaXNzIjoib2F1dGgtZGV2LmhwYnAuaW8iLCJqdGkiOiJjYzMzNGQ0YS1jZGM5LTRhYzctOTE0MC0yNzdmZDg5NWY4ZjgiLCJwbGF0Zm9ybV9yb2xlcyI6ImhwYnAtYWRtaW5pc3RyYXRvciIsInNjcCI6WyJvZmZsaW5lIiwib3BlbmlkIiwib2ZmbGluZV9hY2Nlc3MiXSwic3ViIjoiYzcyN2RlM2ItOTNmYS00YjE4LWE4NzYtY2MyNmNmYzA3MmVmIiwic3ViX2lkcCI6Im9uZWhwIiwidGVuYW50X2lkIjoiaHBicC1kZXYiLCJ0eXBlIjoidXNlciIsInVzZXJfaWQiOiJjNzI3ZGUzYi05M2ZhLTRiMTgtYTg3Ni1jYzI2Y2ZjMDcyZWYiLCJ2ZXJpZmljYXRpb25fZW5hYmxlZCI6ZmFsc2V9.EQw8YlBEr0AwfRYkJWISqi0kMerU68XqqhGM2ms1SwaB51oc_1jOnFznbzv-DznGN_4zl4nwGIlNXXrWrPvMsIwx9kvdcL-Cta_6MjkbQCvxiW-K-oLk7-oBleV8NL37IWF2l0NnKo1bIk9FBo0QrItIiJhO58TVtNvytxae2QT3tvDUmGBXUsktwwMvd0JiLQA2VADfLTKTbyOYo4ik-kjO7lLAt2Pgk6ST1RKWngoHpTY9HMTdiXcnt13ycksHLj_ZS7QDwpG75yDJX25DTjnesrI1J6ka1g46eJkaxs8powPhMQdgl8K96Lq3y8mwx-LYXIP6getqPKo2jYgIFA",
  //   "secretKey"
  // )

  return (
    <ConfigProvider
      theme={{ token: { fontFamily: "Roboto", borderRadius: 4 } }}
    >
      <UserContext.Provider value={{ state, dispatch: dispatch }}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </QueryClientProvider>
      </UserContext.Provider>
    </ConfigProvider>
  );
}

export default App;
