/** @format */

import { useQuery, useQueryClient, useMutation } from "react-query";
import { Toast } from "components/shared/elements/Toast";

export const useConfig = (
  queryKey: any,
  callback: (target: any, old?: any[]) => any[]
) => {
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => queryClient.invalidateQueries(queryKey),
    async onMutate(target: any) {
      const previousItems = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old?: any[]) => {
        return callback(target, old);
      });
      return { previousItems };
    },
    onError: (error: any, newItem: any, context: any) => {
      queryClient.setQueryData(queryKey, context.previousItems);
    },
  };
  return options;
};

export const useUpdateData = (
  queryKey: string,
  mutationFn: (value: any) => any
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (e: any) => {
      if (e.code !== 409) {
        Toast(
          {
            message: e.message,
          },
          "error"
        );
      }
    },
  });
};
