/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const DevPortal = forwardRef(
  ({ width = 24, height = 24, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <rect
        x="8.12891"
        y="8.12891"
        width="15.871"
        height="15.871"
        fill="url(#paint0_angular_817_60014)"
      />
      <rect
        x="15.8711"
        y="15.8711"
        width="8.12904"
        height="8.12904"
        fill="url(#paint1_linear_817_60014)"
      />
      <rect
        width="15.871"
        height="15.871"
        fill="url(#paint2_linear_817_60014)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_817_60014"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0644 16.0644) rotate(90) scale(7.93549)"
        >
          <stop stopColor="#229BFF" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_817_60014"
          x1="19.9356"
          y1="15.8711"
          x2="19.9356"
          y2="24.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#229BFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_817_60014"
          x1="0.774194"
          y1="15.871"
          x2="15.871"
          y2="8.66672e-07"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#229BFF" />
          <stop offset="1" stopColor="#6666FF" />
        </linearGradient>
      </defs>
    </svg>
  )
);

export default DevPortal;
