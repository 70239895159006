export const queryToString = (params) => {
  let queryStr = ''
  Object.entries(params || {}).forEach(([key, value], index) => {
    if (index > 0) {
      queryStr += '&'
    }
    queryStr += `${key}=${encodeURIComponent(value)}`
  })
  return queryStr
}

const API = ({ oAuthApiBase, apiBase, clientId }) => {
  const getHPIDUrl = (params = {}) => {
    return fetch(`${oAuthApiBase}/oauth/v1/auth?${queryToString(params)}`).then((res) => {
      return res.text()
    })
  }

  const getHPIDOpenUrl = (params = {}) => {
    const url = `${oAuthApiBase}/oauth/v1/auth?${queryToString(params)}`
    return url
  }

  const fetchToken = (params) => {
    return fetch(`${apiBase}/v1/oauth/callback`, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: queryToString(params),
    }).then(function (res) {
      return res.json()
    })
  }

  const fetchRefreshToken = (params) => {
    return fetch(`${apiBase}/v1/oauth/refresh`, {
      method: 'POST',
      mode: 'cors',
      headers:  { 'Content-Type': 'application/x-www-form-urlencoded' },//{ 'Content-Type': 'application/json' },
      body:queryToString({ refresh_token: params.refresh_token }),// JSON.stringify({ refresh_token: params.refresh_token }),
    }).then(function (res) {
      return res.json()
    })
  }

  const revokeToken = (token) => {
    return fetch(`${oAuthApiBase}/oauth/v1/token/revoke`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        authorization: `Bearer ${token}`,
      },
      body: queryToString({
        token_type_hint: 'access_token',
        token,
        client_id: clientId,
      }),
    }).then(function (res) {
      return res.json()
    })
  }

  return {
    getHPIDUrl,
    getHPIDOpenUrl,
    fetchRefreshToken,
    fetchToken,
    revokeToken,
  }
}

export default API
