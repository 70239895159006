/** @format */

import React from "react";

import CssLoading from "components/shared/elements/CssLoading";

function SubApplications() {
  return (
    <div id="react-app">
      <CssLoading text="Loading" />
    </div>
  );
}

export default SubApplications;
