/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const SmallLogo = forwardRef(
  ({ width = 88, height = 36, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 88 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <g filter="url(#filter0_f_808_13045)">
        <path
          d="M28.3392 16.125C28.3392 22.9595 22.83 28.5 16.0341 28.5C9.23818 28.5 3.729 22.9595 3.729 16.125C3.729 9.29048 9.23818 3.75 16.0341 3.75C22.83 3.75 28.3392 9.29048 28.3392 16.125Z"
          fill="url(#paint0_angular_808_13045)"
        />
      </g>
      <g filter="url(#filter1_f_808_13045)">
        <ellipse
          cx="16.4068"
          cy="16.5"
          rx="11.9322"
          ry="12"
          fill="url(#paint1_angular_808_13045)"
        />
      </g>
      <g filter="url(#filter2_f_808_13045)">
        <ellipse
          cx="16.4071"
          cy="16.5"
          rx="11.1864"
          ry="11.25"
          fill="url(#paint2_angular_808_13045)"
        />
      </g>
      <g filter="url(#filter3_f_808_13045)">
        <ellipse cx="17.8985" cy="18" rx="11.9322" ry="12" fill="#2E2E2E" />
      </g>
      <path d="M49.9663 13.25L51.4578 12.5V23.25H49.9663V13.25Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5295 12.8829C31.8212 12.5568 31.0335 12.375 30.2036 12.375C27.1145 12.375 24.6104 14.8934 24.6104 18C24.6104 19.8908 25.538 21.5637 26.9605 22.5835L27.711 21.2762C26.7327 20.5223 26.1019 19.3353 26.1019 18C26.1019 15.7218 27.9383 13.875 30.2036 13.875C30.7618 13.875 31.2939 13.9872 31.779 14.1902L32.5295 12.8829ZM28.6281 21.8098C29.1132 22.0129 29.6454 22.125 30.2036 22.125C32.4689 22.125 34.3053 20.2782 34.3053 18C34.3053 16.6647 33.6744 15.4777 32.6961 14.7237L33.4466 13.4165C34.8692 14.4363 35.7968 16.1092 35.7968 18C35.7968 21.1066 33.2926 23.625 30.2036 23.625C29.3736 23.625 28.5859 23.4432 27.8776 23.1171L28.6281 21.8098Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.0548 12.8829C71.3465 12.5568 70.5589 12.375 69.729 12.375C66.6399 12.375 64.1357 14.8934 64.1357 18C64.1357 19.8907 65.0633 21.5636 66.4858 22.5834L67.2363 21.2763C66.258 20.5223 65.6271 19.3353 65.6271 18C65.6271 15.7218 67.4635 13.875 69.7288 13.875C70.287 13.875 70.8192 13.9872 71.3043 14.1903L72.0548 12.8829ZM68.1534 21.8098C68.6385 22.0129 69.1706 22.125 69.7288 22.125C71.9941 22.125 73.8305 20.2782 73.8305 18C73.8305 16.6648 73.1997 15.4777 72.2214 14.7238L72.9719 13.4164C74.3945 14.4362 75.3222 16.1092 75.3222 18C75.3222 21.1066 72.818 23.625 69.729 23.625C68.899 23.625 68.1112 23.4432 67.4029 23.117L68.1534 21.8098Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.5596 13.25L79.0511 12.5V23.25H77.5596V13.25ZM85.0171 13.25L86.5086 12.5V23.25H85.0171L84.6442 23.25L79.7969 15.25V12.5L85.0171 21.0189V13.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4238 13.25L14.9154 12.5V23.25H13.4238V13.25ZM20.8815 13.25L22.373 12.5V18.7498L22.3732 18.75H22.373V23.25H20.8815V18.75H15.6613V17.25H20.8815V13.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.6465 12.75H54.4409V14.25H59.5811L60.6465 12.75ZM61.5257 12.7501H63.0172V13.2752L56.998 21.75H63.3902V23.25H55.9326L55.9325 23.2501H54.4409V22.7252L61.5257 12.7501Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0342 13.5L39.5257 12.75V23.25H38.0342V13.5ZM43.6274 19.5C45.4808 19.5 46.9833 17.989 46.9833 16.125C46.9833 14.3029 45.5475 12.818 43.7517 12.7523V12.75H43.6274H40.2716V14.25H43.6274V14.25C44.6571 14.25 45.4918 15.0895 45.4918 16.125C45.4918 17.1606 44.6571 18 43.6274 18L43.6274 19.5ZM42.1358 18H43.6274V19.5H43.5919L47.2319 23.25H45.2432L40.2714 18H42.1358Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_f_808_13045"
          x="0.895671"
          y="0.916667"
          width="30.277"
          height="30.4167"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.41667"
            result="effect1_foregroundBlur_808_13045"
          />
        </filter>
        <filter
          id="filter1_f_808_13045"
          x="3.05794"
          y="3.08333"
          width="26.6976"
          height="26.8333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.708333"
            result="effect1_foregroundBlur_808_13045"
          />
        </filter>
        <filter
          id="filter2_f_808_13045"
          x="4.1582"
          y="4.1875"
          width="24.498"
          height="24.625"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.53125"
            result="effect1_foregroundBlur_808_13045"
          />
        </filter>
        <filter
          id="filter3_f_808_13045"
          x="5.61214"
          y="5.64583"
          width="24.5726"
          height="24.7083"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.177083"
            result="effect1_foregroundBlur_808_13045"
          />
        </filter>
        <radialGradient
          id="paint0_angular_808_13045"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.0341 16.125) rotate(90) scale(12.375 12.3051)"
        >
          <stop offset="0.0989583" stopColor="#6666FF" />
          <stop offset="0.354167" stopColor="#5883FF" />
          <stop offset="0.604167" stopColor="#5AD3FF" />
        </radialGradient>
        <radialGradient
          id="paint1_angular_808_13045"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4068 16.5) rotate(90) scale(12 11.9322)"
        >
          <stop offset="0.0989583" stopColor="#6666FF" />
          <stop offset="0.354167" stopColor="#5883FF" />
          <stop offset="0.604167" stopColor="#5AD3FF" />
        </radialGradient>
        <radialGradient
          id="paint2_angular_808_13045"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.4071 16.5) rotate(90) scale(11.25 11.1864)"
        >
          <stop offset="0.148623" stopColor="#9A9AFF" />
          <stop offset="0.354167" stopColor="#FFF9D8" />
          <stop offset="0.549685" stopColor="#98E4FF" />
        </radialGradient>
      </defs>
    </svg>
  )
);

export default SmallLogo;
