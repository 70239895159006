/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const Camera = forwardRef(
  ({ width = 20, height = 20, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 14.0106C11.9932 14.0106 13.6092 12.3946 13.6092 10.4012C13.6092 8.4078 11.9932 6.79183 9.99984 6.79183C8.00643 6.79183 6.39046 8.4078 6.39046 10.4012C6.39046 12.3946 8.00643 14.0106 9.99984 14.0106ZM9.99984 13.2085C11.5503 13.2085 12.8071 11.9516 12.8071 10.4012C12.8071 8.85078 11.5503 7.59391 9.99984 7.59391C8.44941 7.59391 7.19255 8.85078 7.19255 10.4012C7.19255 11.9516 8.44941 13.2085 9.99984 13.2085Z"
        fill="white"
      />
      <path
        d="M15.6144 9.19808C16.0574 9.19808 16.4165 8.83897 16.4165 8.396C16.4165 7.95302 16.0574 7.59391 15.6144 7.59391C15.1714 7.59391 14.8123 7.95302 14.8123 8.396C14.8123 8.83897 15.1714 9.19808 15.6144 9.19808Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10027 4.47026C6.372 3.92679 6.92746 3.5835 7.53508 3.5835H12.4646C13.0722 3.5835 13.6277 3.92679 13.8994 4.47026L14.4375 5.54637C14.5733 5.8181 14.8511 5.98975 15.1549 5.98975H16.4165C17.3025 5.98975 18.0207 6.70796 18.0207 7.59391V14.8127C18.0207 15.6986 17.3025 16.4168 16.4165 16.4168H3.58317C2.69721 16.4168 1.979 15.6986 1.979 14.8127V7.59391C1.979 6.70796 2.69721 5.98975 3.58317 5.98975H4.84481C5.14861 5.98975 5.42635 5.8181 5.56221 5.54637L6.10027 4.47026ZM7.53508 4.38558C7.23127 4.38558 6.95354 4.55723 6.81767 4.82896L6.27962 5.90507C6.00788 6.44853 5.45242 6.79183 4.84481 6.79183H3.58317C3.14019 6.79183 2.78109 7.15093 2.78109 7.59391V14.8127C2.78109 15.2556 3.14019 15.6147 3.58317 15.6147H16.4165C16.8595 15.6147 17.2186 15.2556 17.2186 14.8127V7.59391C17.2186 7.15093 16.8595 6.79183 16.4165 6.79183H15.1549C14.5473 6.79183 13.9918 6.44853 13.7201 5.90507L13.182 4.82896C13.0461 4.55723 12.7684 4.38558 12.4646 4.38558H7.53508Z"
        fill="white"
      />
    </svg>
  )
);

export default Camera;
