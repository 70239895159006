/** @format */

import React, { FC, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import * as R from "ramda";
import { Spin } from "antd";

const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: { height: string | null | undefined }) =>
    props.height || `calc(100vh - 60px)`};
`;

const LoadingSpan = styled.span`
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
    }
  }
  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  :after,
  :before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background: linear-gradient(
      185.36deg,
      #afdbff -59.27%,
      #5ad3ff -8.06%,
      #7c71ff 87.16%
    );
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }
  :before {
    background: linear-gradient(185.36deg, #afdbff -11.58%, #5ad3ff 41.95%);
    transform: scale(0.5) translate(-48px, -48px);
  }
`;

interface LoadingProps {
  text?: string;
  height?: string;
}

const Loading: FC<LoadingProps> = ({ text = "loading", height }) => {
  return (
    <StyledContainer height={height}>
      <LoadingSpan></LoadingSpan>
    </StyledContainer>
  );
};

export default Loading;
