/** @format */

import React, { FC, ReactNode } from "react";
import { Form } from "antd";
import { useField } from "formik";

import { StyleInput } from "../common";

interface InputProps {
  name: string;
  label?: string | ReactNode;
  placeholder?: string;
  required?: boolean;
  className?: any;
  maxLength?: number;
  disabled?: boolean;
  style?: {};
  onChange?: (e: any) => void;
}

const InputForm: FC<InputProps> = (props) => {
  const {
    label,
    required,
    placeholder,
    className,
    maxLength,
    disabled = false,
    style = {},
    onChange = () => {},
    ...restProps
  } = props;
  const [field, meta, helpers] = useField(props);
  return (
    <Form.Item
      label={label}
      validateStatus={meta.error && meta.touched ? "error" : "success"}
      help={meta.touched && meta.error}
      required={required}
    >
      <StyleInput
        placeholder={placeholder}
        {...field}
        className={className}
        maxLength={maxLength}
        {...restProps}
        disabled={disabled}
        autoComplete="off"
        onChange={(e) => {
          field.onChange(e);
          onChange(e);
        }}
        style={style}
      />
    </Form.Item>
  );
};

export default InputForm;
