/**
 * /* eslint-disable max-lines
 *
 * @format
 */

/* eslint-disable no-unused-vars */
import { requestCreator, requestOptionsCreator } from "utils/apiHelper";

let request: any;

export const init = (token: string, apiBase: string) => {
  request = requestCreator(apiBase, requestOptionsCreator(token));
};
// *** api for HPBP admin starts

export const adminFetchProjects = (queryParams: any) =>
  request({ url: `/v1/platform/projects`, queryParams });

export const fetchUserProfile = () => request({ url: `/v1/users/profile` });

export const fetchUserAvatarDownloadUrl = () =>
  request({ url: `/v1/users/avatar/download` });

export const fetchUser = () => request({ url: `/v1/users/me` });

export const fetchUserAvatar = () =>
  request({ url: `/v1/users/avatar/download` });

export const updateUser = (body?: any) =>
  request({
    url: `/v1/users/me`,
    body,
    method: "PATCH",
  });

export const fetchUploadAvatar = () =>
  request({ url: `/v1/users/avatar/upload` });

export const deleteAvatar = () =>
  request({ url: `/v1/users/avatar`, method: "DELETE" });
