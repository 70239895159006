/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { ReactNode } from "react";
import { Modal } from "antd";
import styled from "styled-components";

const Footer = styled.div`
  text-align: right !important;
  // margin: 0 -40px;
  // border-top: 1px solid #e8e8e8;
  padding: 10px 0px 5px !important;
`;

export const StyleModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 4px !important;
  }
`;

interface PropTypes {
  title: string | ReactNode;
  isVisible: boolean;
  closable: boolean;
  width: number;
  buttons: any;
  onClose: () => void;
  maskStyle: {};
  bodyStyle: {};
  children: any;
  wrapClassName?: string;
}

const FormModal = ({
  title,
  isVisible,
  width,
  closable,
  children,
  buttons,
  onClose,
  bodyStyle,
  maskStyle,
  wrapClassName,
  ...resProps
}: PropTypes) => (
  <StyleModal
    title={title}
    open={isVisible}
    footer={null}
    onCancel={onClose}
    styles={{ body: bodyStyle, mask: maskStyle }}
    width={width}
    destroyOnClose
    keyboard={false}
    maskClosable={false}
    closable={closable}
    centered={false}
    wrapClassName={wrapClassName}
    {...resProps}
  >
    {children}
    <Footer className="ant-modal-footer">{buttons}</Footer>
  </StyleModal>
);

export default FormModal;
