/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */
import * as Yup from "yup";
import { path } from "ramda";

export const nameStartReg = new RegExp(/^[a-z]+[a-z0-9]*$/);
export const nameReg = new RegExp(/^[a-z0-9]*$/);
export const displayNameStartReg = new RegExp(/^[a-zA-Z]+[a-zA-Z0-9]*$/);
export const displayNameReg = new RegExp(/^[a-zA-Z0-9]*$/);
export const descriptionReg = new RegExp(/^[^<>]+$/);
export const emailReg = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

Yup.addMethod(
  Yup.object,
  "uniqueProperty",
  function cb1(propertyName, message, excludes = []) {
    return this.test("unique", message, function cb2(value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const options = [...this.parent];
      const currentIndex = options.indexOf(value);
      const subOptions = options.slice(0, currentIndex);

      if (
        subOptions.some(
          (option) => option[propertyName] === value[propertyName]
        )
      ) {
        if (excludes.includes(value[propertyName])) {
          return true;
        }
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }
      return true;
    });
  }
);

export const descriptionSchema = Yup.string()
  .max(500, "The length of the description cannot exceed 500")
  .matches(descriptionReg, "Description cannot include “<” or “>”");

export const validateEmails = Yup.object().shape({
  emails: Yup.array()
    .of(
      Yup.string().matches(
        /^[a-zA-Z0-9_\\.-]+@+(hp\.com)+$/,
        "These addresses have already direct or indirect members of this project or non-effective email addresses "
      )
    )
    .max(50, "The maximum amount of mailboxes added is 50")
    .min(1, "  "),
});

export const validateUser = (errors?: any) =>
  Yup.object().shape({
    firstName: Yup.string()
      .min(3, "The length of the first name cannot be less than 3")
      .max(100, "The length of the first name cannot exceed 100")
      .matches(descriptionReg, "The first name cannot include “<” or “>”")
      .required(" "),
    lastName: Yup.string()
      .min(3, "The length of the last name cannot be less than 3")
      .max(100, "The length of the last name cannot exceed 100")
      .matches(descriptionReg, "The last name cannot include “<” or “>”")
      .required(" "),
    email: Yup.string().matches(emailReg, "Email format is incorrect"),
  });
