/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SettingOutlined } from "@ant-design/icons";
import { path } from "ramda";

import DefaultAvatar from "components/User/DefaultAvatar";

const Container = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  padding: 8px 10px 8px 14px;
  background: #fff;
`;
const AvatarContainer = styled.div``;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const RightInfo = styled.div`
  margin-left: 20px;
`;
const Setting = styled.div`
  color: #435761;
  text-decoration: underline;
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: #229bff;
  }
`;
const SettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  position: absolute;
  right: 20px;
`;

const UserName = styled.div`
  color: #2e2e2e;
  font-weight: 500;
  font-size: 18px;
  width: 150px;
  word-wrap: break-word;
  word-break: break-all;
`;

const StyledEmail = styled.div`
  font-weight: 400;
  font-size: 14px;
  width: 150px;
  word-wrap: break-word;
  word-break: break-all;
  color: #949494;
`;

const StyledRole = styled.div`
  color: #435761;
  font-size: 14px;
  font-weight: 400;
  display: none;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Profile {
  firstName: string;
  lastName: string;
  id: string;
  roles: {
    displayName: string;
  }[];
  email: string;
}

// const defaultProfile = {
//   userInfo: {
//     firstName: "",
//     lastName: ""
//   },
//   id: "",
//   roles: [{ displayName: "" }],
//   email: ""
// };

const ConnerUserInfo = ({
  profile,
  handleViewUserInfo = () => {},
}: {
  profile: Profile;
  handleViewUserInfo?: () => void;
}) => {
  const [roleName, setRoleName] = useState<string>();
  //const handleViewUserInfo = () => {
  //  history.push(`/user/${profile.id}`);
  // };

  const roles = path(["roles"], profile) || [];
  useEffect(() => {
    if (roles.length > 1) {
      const name = roles.find(
        (item) => item.displayName === "HORIZON_ADMINISTRATOR"
      )!.displayName;
      setRoleName(name);
    } else if (roles.length === 1) {
      const name = roles[0].displayName;
      setRoleName(name);
    } else {
      setRoleName("");
    }
  }, [roles]);

  const userInfo = path(["userInfo"], profile);
  const userName = profile
    ? `${path(["firstName"], profile)} ${path(["lastName"], profile)}`
    : "";

  return (
    <Container>
      {roleName && (
        <SettingContainer>
          <StyledRole data-auid="AUID_Conner_User_Info_Role_Trigger">
            {roleName}
          </StyledRole>
          <Setting
            onClick={handleViewUserInfo}
            data-auid="AUID_Conner_User_Info_Setting_Trigger"
          >
            <SettingOutlined rev="true" />
          </Setting>
        </SettingContainer>
      )}
      <InfoContainer>
        <LeftContainer>
          <AvatarContainer data-auid="AUID_Conner_User_Info_Avatar_Trigger">
            <DefaultAvatar
              size={{ height: "26px", width: "26px" }}
              fontSize="12px"
              profile={profile}
            />
          </AvatarContainer>
          <RightInfo data-auid="AUID_Conner_User_Info_Description_Trigger">
            <UserName>{userName}</UserName>
            <StyledEmail>{path(["email"], profile)}</StyledEmail>
          </RightInfo>
        </LeftContainer>
      </InfoContainer>
    </Container>
  );
};

export default ConnerUserInfo;
