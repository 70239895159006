import React from "react";
import {
  toast,
  Slide,
  Zoom,
  Flip,
  Bounce,
  ToastContainer,
} from "react-toastify";
import { path, curry, compose, mergeDeepRight } from "ramda";
import PropTypes from "prop-types";
import ErrorIcon from "assets/Error";

const toastPosition: any = {
  topCenter: toast.POSITION.TOP_CENTER,
  topRight: toast.POSITION.TOP_RIGHT,
  topLeft: toast.POSITION.TOP_LEFT,
  bottomLeft: toast.POSITION.BOTTOM_LEFT,
  bottomCenter: toast.POSITION.BOTTOM_CENTER,
  bottomRight: toast.POSITION.BOTTOM_RIGHT,
};

interface ToastPorps {
  message?: string;
  delay?: string | number;
  position?: string;
  theme?: string;
  transition?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

const defaultErrorMessageConfig: any = { 403: "Permission denied." };

const customErrorIcon = ({ theme, type }: { theme: string; type: string }) => {
  return <ErrorIcon fill={theme === "colored" ? "#fff" : "#e74c3c"} />;
};

export const Toast = (
  {
    message,
    delay,
    position = "topRight",
    theme = "light",
    transition = "bounce",
    onOpen,
    onClose,
  }: ToastPorps,
  type: "info" | "success" | "warn" | "error"
) => {
  const toastId = message;

  const insideTransition: any = {
    slide: Slide,
    zoom: Zoom,
    flip: Flip,
    bounce: Bounce,
  };

  const options: any = {
    toastId,
    autoClose: delay || 7000,
    position: toastPosition[position],
    theme,
    progress: undefined,
    transition: insideTransition[transition],
    onOpen: typeof onOpen === "function" ? onOpen : () => {},
    onClose: typeof onClose === "function" ? onClose : () => {},
  };

  const msgNode = <span style={{ fontSize: "12px" }}>{message}</span>;

  switch (type) {
    case "info":
      toast.info(msgNode, options);
      break;
    case "success":
      toast.success(msgNode, { ...options, autoClose: delay || 3000 });
      break;
    case "warn":
      toast.warn(msgNode, options);
      break;
    case "error":
      toast.error(msgNode, {
        icon: customErrorIcon,
        ...options,
      });
      break;
    default:
      toast(msgNode, options);
  }
};

const ToastNode = ({
  newestOnTop,
  limit,
}: {
  newestOnTop: any;
  limit: any;
}) => {
  return <ToastContainer newestOnTop={newestOnTop} limit={limit} />;
};

ToastNode.defaultProps = {
  newestOnTop: true,
  limit: 3,
};

ToastNode.propTypes = {
  newestOnTop: PropTypes.bool,
  limit: PropTypes.number,
};

export default ToastNode;

export const responseErrorHandler = curry((config, error) =>
  Toast(
    {
      message:
        compose(
          path([error.status]),
          mergeDeepRight(defaultErrorMessageConfig)
        )(config) ||
        config.msg ||
        error.message,
    },
    "error"
  )
);

export const basicErrorHandler = (msg: any) => responseErrorHandler({ msg });
