/** @format */

import { createContext } from "react";
import initState, { statesTypes, actions } from "./initialState";

type props = {
  state: statesTypes;
  dispatch: React.Dispatch<actions>;
};

const entity = {
  state: initState,
  dispatch: () => undefined,
};

const Context = createContext<props>(entity);

export default Context;
