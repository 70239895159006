interface originalImageProp {}

const fileToData = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (ev: any) {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const dataURLtoFile = (dataurl: any, filename: string) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
};

export const compressImage = async (originalImage: any, compressRatio = 1) => {
  const image = new Image();
  const result: any = await fileToData(originalImage);
  image.src = result;

  return new Promise((resolve, reject) => {
    image.addEventListener("load", function () {
      let [sizeRatio, maxWidth, maxHeight] = [0, 64, 64];
      let [imageWidth, imageHeight] = [this.naturalWidth, this.naturalHeight];
      let compressFlag = false;

      if (imageWidth > maxWidth) {
        compressFlag = true;
        sizeRatio = imageWidth / maxWidth;
        maxHeight = imageHeight / sizeRatio;
      }

      if (imageHeight > maxHeight) {
        compressFlag = true;
        sizeRatio = imageHeight / maxHeight;
        maxWidth = imageWidth / sizeRatio;
      }

      if (!compressFlag) {
        maxWidth = imageWidth;
        maxHeight = imageHeight;
      }

      const canvas = document.createElement("canvas");
      const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

      canvas.setAttribute("id", "canvas");
      canvas.width = maxWidth;
      canvas.height = maxHeight;
      if (ctx) {
        ctx.clearRect(0, 0, maxWidth, maxHeight);
        ctx.drawImage(image, 0, 0, maxWidth, maxHeight);
        const compressImage = canvas.toDataURL("image/jpeg", compressRatio);
        resolve(compressImage);
      }
    });
  });
};
