/** @format */

import { requestCreator, requestOptionsCreator } from "utils/authApiHelper";

import { oauthCallback, refreshTokenEndpoint } from "constants/env";

let request: any;

export const init = (store: any, apiBase: string) => {
  const getTokenFromState = () => {
    return (store.auth && store.auth.token) || "";
  };
  const requestOptions = requestOptionsCreator(getTokenFromState);
  request = requestCreator(apiBase, requestOptions, store);
};

export const getHPIDUrl = (params: any) =>
  request({ url: `/oauth/v1/auth`, queryParams: params });

export const fetchToken = (queryParams: any) =>
  request({ url: oauthCallback, queryParams }, true);

export const fetchUser = (token: string, userId: string) =>
  request({ url: `/console/user/${userId}`, token: `Bearer ${token}` });

export const logout = (
  token: any,
  refreshToken: string | boolean,
  clientId: string
) => {
  request({
    url: "/oauth/v1/token/revoke",
    body: { tokenTypeHint: "access_token", token, clientId },
    method: "POST",
    asForm: true,
  });
  if (refreshToken) {
    request({
      url: "/oauth/v1/token/revoke",
      body: { tokenTypeHint: "refresh_token", token: refreshToken, clientId },
      method: "POST",
      asForm: true,
    });
  }
};

export const getSolutions = async () => {
  const resp = await fetch(`/modules.json?ran=${Math.random()}`);
  const data = await resp.text();
  return JSON.parse(data);
};

export const refreshAccessToken = (refreshToken: string) =>
  request(
    { url: refreshTokenEndpoint, body: { refreshToken }, method: "POST" },
    true
  );
