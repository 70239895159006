/**
 * /* eslint-disable spaced-comment
 *
 * @format
 */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Suspense, lazy, useCallback } from "react";
import { Routes, Route, Link, useLocation, useParams } from "react-router-dom";
import NProgress from "components/shared/elements/NProgress";
import styled from "styled-components";
import * as R from "ramda";

import { setStorageValue, getStorageValue } from "utils/useLocalStorage";
import ToastNode from "components/shared/elements/Toast";
import SubApplications from "./SubApplications";
import ErrorBoundary from "components/shared/elements/ErrorBoundary";
import TopBar from "components/Main/TopBar";
import * as api from "api";
import actions from "components/shared/actions";
import { tokenManager } from "token";
import { retryLoad } from "utils/retryLoad";
import { solutionPath } from "constants/options";
import { apiConsoleBase } from "constants/env";
import Postman from "./Postman";

const Home = lazy(() => retryLoad(import("./Home")));

const StyledLayout = styled.div``;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 905px;
  overflow-x: auto;
`;

const apiBaseConsole = apiConsoleBase;

const loggedOut = false;

const Private = () => {
  const params = useParams();
  const location = useLocation();
  const token = "";
  api.init(token, apiBaseConsole);
  const pathnames = R.split("/", location.pathname);

  useEffect(() => {
    const pathName = R.path(["*"], params) || "";
    const solutionName = getStorageValue("horizon:console");
    const INSIGHTS = solutionPath.insights;
    const CONSOLE = solutionPath.console;
    if (pathName.indexOf(INSIGHTS) > -1 && solutionName !== INSIGHTS) {
      setStorageValue("horizon:console", INSIGHTS);
    }
    if (pathName.indexOf(CONSOLE) > -1 && solutionName !== CONSOLE) {
      setStorageValue("horizon:console", CONSOLE);
    }

    if (pathName.indexOf(INSIGHTS) < 0 && pathName.indexOf(CONSOLE) < 0) {
      setStorageValue("horizon:console", "home");
    }
  }, [params]);

  actions.onGlobalStateChange((state, prev) => {
    // state: 变更后的状态; prev 变更前的状态
    //   toggleVisiable(state.showTopBar);
  });

  const logout = useCallback(() => {
    tokenManager.logout();
  }, []);

  return (
    <>
      {pathnames[2] !== "fullscreen" && <TopBar logout={logout} />}
      <button
        style={{ display: "none" }}
        onClick={() => {
          actions.setGlobalState({ name: "123" });
        }}
      >
        and
      </button>

      <Routes>
        <Route
          path="/home"
          element={
            <ErrorBoundary>
              <Home />
            </ErrorBoundary>
          }
        />
        <Route
          path="/apicall"
          element={
            <ErrorBoundary>
              <Postman />
            </ErrorBoundary>
          }
        />
        <Route path="/*" element={<SubApplications />} />
      </Routes>
      <ToastNode />
    </>
  );
};

Private.defaultProps = {
  token: null,
  refreshToken: null,
};

export default Private;
