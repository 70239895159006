import React, { useState } from "react";
import { Form, Row, Button, Input, Select } from "antd";
import styled from "styled-components";

import * as creator from "./apiHelper";

const { TextArea } = Input;

const StyledContainer = styled.div`
  padding: 65px;
`;

const StyledHeader = styled.div`
  height: 65px;
`;

function Postman() {
  const [url, setUrl] = useState<string>("https://hpbp-dev.hpbp.io");
  const [path, setPath] = useState<string>("/gaia/api/v1/users/profile");
  const [token, setToken] = useState<string>(
    "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk3NGZmM2NjLTA3MGItNDMwYy05NzMwLWE2YzU1ZjYyOGMxOSIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiaHR0cHM6Ly9ocGJwLXN0Zy5ocGJwLmlvIiwiaHR0cDovL2xvY2FsaG9zdDozMDAwIl0sImNsaWVudF9pZCI6IjgwMzBkMzVkLTkwYzQtNGViOS1iOWMyLWRhYjgzNjVjMTVjOSIsImVudmlyb25tZW50X2lkIjoiN2RlNTgyOTAtNzFkYy00YzVlLTg3NDItZGYxZGM4NDQ4N2FlIiwiZXhwIjoxNjk4Mzc3NzkzLCJpYXQiOjE2OTgzNzQxOTMsImlkcCI6ImhwaWRfc3RnIiwiaXNzIjoib2F1dGgtc3RnLmhwYnAuaW8iLCJqdGkiOiJjZTUwNTkxMy05ZTJlLTQyZTMtYmQwNy1kZGVhYzYwYTk0ZmIiLCJwbGF0Zm9ybV9yb2xlcyI6IiIsInNjcCI6WyJvZmZsaW5lIl0sInN1YiI6Ijc5OGIxNmZiLWVmZDYtNDZiMi05OTk1LTY3MWIyODRjODIzMSIsInN1Yl9pZHAiOiJvbmVocCIsInRlbmFudF9pZCI6ImhwYnAtc3RnIiwidHlwZSI6InVzZXIiLCJ1c2VyX2lkIjoiNzk4YjE2ZmItZWZkNi00NmIyLTk5OTUtNjcxYjI4NGM4MjMxIiwidmVyaWZpY2F0aW9uX2VuYWJsZWQiOmZhbHNlfQ.rvztOuhFC4ZvVRnlavnOJJrHdLxWKlYJpJw1jx-jCAplT2mVsbpr0-r3cMdv1TK6NlYZ-6JQRfFiVApva28dccC_mBLpZgEaW9cxJeFH8Y-UQDWQgX4atvGh5pif_UW1baDiNwMIGEmLHtyWAedhm2XLZHLb0g57-9xxHwWm42M4BTRMBrUdC6om7azclPRzn77M8xCDXOuzodOndbMlJhaKT-FoIFU6X66zqplzm99WkwimzRUMu3aoZXhj7-DRMql5L4D-SbW4ar2nIHXQ4KtAxN8L_5XcbyWqMUY8xNW4MzZC6-frgVfNsPySWQ-OZw7vF3mY3zZ7m0jNWoko5A"
  );

  const email = {
    templateId: "de71978b-4f6a-4665-a07b-722e42b96504",
    subjectValues: "Deleted: Old secret was deleted",
    contentValues: {
      date: "2023-10-27T01:01:10Z",
      projectName: "devPaid",
      environmentName: "dev",
      appKey: "7ca0064d-e63e-4dc1-9d3f-5c46e8ec1466",
      appSecret: "dhvpi*************YgAe6",
      deletedBy: "chen.jing@hp.com",
    },
    toAddresses: "chen.jing@hp.com",
    fromAddress: {
      address: "chen.jing@hp.com",
    },
    separateSend: true,
  };

  const [body, setBody] = useState<any>(null);
  const [method, setMethod] = useState<string>("GET");
  const [TenantID, setTenantID] = useState<any>(null);
  const [queryParams, setQueryParams] = useState<any>(null);

  const onPost = async () => {
    const { requestCreator, requestOptionsCreator } = creator;
    const request = requestCreator(url, requestOptionsCreator(token, TenantID));

    try {
      const params: any = {
        url: path,
        queryParams: queryParams ? JSON.parse(queryParams) : null,
        body: body ? JSON.parse(body) : null,
        method,
      };
      const result = await request(params);
    } catch (e) {}
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <Row>
          <Form.Item label="url">
            <Input
              defaultValue={url}
              onChange={(e: any) => {
                setUrl(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="path">
            <Input
              defaultValue={path}
              onChange={(e: any) => {
                setPath(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="method">
            <Select
              defaultValue={method}
              style={{ width: 120 }}
              onChange={(e) => {
                setMethod(e);
              }}
              options={[
                { value: "GET", label: "GET" },
                { value: "POST", label: "POST" },
                { value: "PATCH", label: "PATCH" },
                { value: "DELETE", label: "DELETE" },
              ]}
            />
          </Form.Item>
          <Form.Item label="TenantID">
            <Select
              defaultValue={TenantID}
              style={{ width: 120 }}
              onChange={(e) => {
                setTenantID(e);
              }}
              options={[
                { value: "Null", label: "" },
                { value: "hpbp-dev", label: "hpbp-dev" },
                { value: "hpbp-int", label: "hpbp-int" },
                { value: "hpbp-stg", label: "hpbp-stg" },
              ]}
            />
          </Form.Item>
          <Button type="primary" onClick={onPost}>
            Post
          </Button>
        </Row>
      </StyledHeader>
      <div>
        <Form.Item label="token">
          <TextArea
            defaultValue="eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk3NGZmM2NjLTA3MGItNDMwYy05NzMwLWE2YzU1ZjYyOGMxOSIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsiaHR0cHM6Ly9ocGJwLXN0Zy5ocGJwLmlvIiwiaHR0cDovL2xvY2FsaG9zdDozMDAwIl0sImNsaWVudF9pZCI6IjgwMzBkMzVkLTkwYzQtNGViOS1iOWMyLWRhYjgzNjVjMTVjOSIsImVudmlyb25tZW50X2lkIjoiN2RlNTgyOTAtNzFkYy00YzVlLTg3NDItZGYxZGM4NDQ4N2FlIiwiZXhwIjoxNjk4Mzc3NzkzLCJpYXQiOjE2OTgzNzQxOTMsImlkcCI6ImhwaWRfc3RnIiwiaXNzIjoib2F1dGgtc3RnLmhwYnAuaW8iLCJqdGkiOiJjZTUwNTkxMy05ZTJlLTQyZTMtYmQwNy1kZGVhYzYwYTk0ZmIiLCJwbGF0Zm9ybV9yb2xlcyI6IiIsInNjcCI6WyJvZmZsaW5lIl0sInN1YiI6Ijc5OGIxNmZiLWVmZDYtNDZiMi05OTk1LTY3MWIyODRjODIzMSIsInN1Yl9pZHAiOiJvbmVocCIsInRlbmFudF9pZCI6ImhwYnAtc3RnIiwidHlwZSI6InVzZXIiLCJ1c2VyX2lkIjoiNzk4YjE2ZmItZWZkNi00NmIyLTk5OTUtNjcxYjI4NGM4MjMxIiwidmVyaWZpY2F0aW9uX2VuYWJsZWQiOmZhbHNlfQ.rvztOuhFC4ZvVRnlavnOJJrHdLxWKlYJpJw1jx-jCAplT2mVsbpr0-r3cMdv1TK6NlYZ-6JQRfFiVApva28dccC_mBLpZgEaW9cxJeFH8Y-UQDWQgX4atvGh5pif_UW1baDiNwMIGEmLHtyWAedhm2XLZHLb0g57-9xxHwWm42M4BTRMBrUdC6om7azclPRzn77M8xCDXOuzodOndbMlJhaKT-FoIFU6X66zqplzm99WkwimzRUMu3aoZXhj7-DRMql5L4D-SbW4ar2nIHXQ4KtAxN8L_5XcbyWqMUY8xNW4MzZC6-frgVfNsPySWQ-OZw7vF3mY3zZ7m0jNWoko5A"
            rows={4}
            onChange={(e: any) => {
              setToken(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="post">
          <TextArea
            rows={4}
            onChange={(e: any) => {
              setBody(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="get">
          <TextArea
            rows={4}
            onChange={(e: any) => {
              setQueryParams(e.target.value);
            }}
          />
        </Form.Item>
      </div>
    </StyledContainer>
  );
}

export default Postman;
