/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import { registerMicroApps, start } from "qiankun";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertFromHTML,
  ContentState,
  Modifier,
  convertToRaw,
  ContentBlock,
  CharacterMetadata,
} from "draft-js";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { requestCreator, requestOptionsCreator } from "utils/apiHelper";
import ToastNode, {
  Toast,
  responseErrorHandler,
  basicErrorHandler,
} from "components/shared/elements/Toast";
import { getStorageToken } from "utils/stringUtil";
import { routerName } from "constants/env";

const devPortalUrl =
  window.REACT_APP_DEVELOPER_PORTAL_URL ||
  process.env.REACT_APP_DEVELOPER_PORTAL_URL;
const insightUrl =
  window.REACT_APP_INSIGHT_URL || process.env.REACT_APP_INSIGHT_URL;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);

function genActiveRule(routerPrefix: string) {
  return (location: any) => location.pathname.startsWith(routerPrefix);
}

const subProps = {
  apiHelper: {
    requestCreator: requestCreator,
    requestOptionsCreator: requestOptionsCreator,
  },
  auth: {
    getStorageToken: getStorageToken,
  },
  UI: {
    Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    convertFromHTML,
    ContentState,
    Modifier,
    convertToRaw,
    ContentBlock,
    CharacterMetadata,
  },
  toast: {
    ToastNode: ToastNode,
    Toast: Toast,
    responseErrorHandler: responseErrorHandler,
    basicErrorHandler: basicErrorHandler,
  },
};

const timestamp = new Date().getTime();

registerMicroApps([
  {
    name: "horizon-1.0.0",
    entry: `${devPortalUrl}?v=${timestamp}`, //"https://portal-dev2.hpbp.io",
    container: "#react-app",
    activeRule: genActiveRule("/console"),
    props: subProps,
  },
  {
    name: "insight-portal",
    entry: `${insightUrl}?v=${timestamp}`, //"https://insights-dev2.hpbp.io",
    container: "#react-app",
    activeRule: genActiveRule(`/${routerName}`),
    props: subProps,
  },
  //   {
  //     name: "portal",
  //     entry: `//localhost:3400?v=${timestamp}`, //"https://insights-dev2.hpbp.io",
  //     container: "#react-app",
  //     activeRule: genActiveRule(`/portal`),
  //     props: subProps,
  //   },
]);

start({ prefetch: true });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
