/**
 * /* eslint-disable react/jsx-props-no-spreading
 *
 * @format
 */

import React, { forwardRef } from "react";

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
  style?: {};
}

const SPTopLogo = forwardRef(
  ({ width = 92, height = 32, style, fill, ...restProps }: IconProps, ref) => (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 92 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <rect
        x="0.136625"
        y="1.10782"
        width="29.7843"
        height="29.7843"
        rx="7.09944"
        fill="url(#paint0_linear_4865_37294)"
        stroke="#E1E7FF"
        strokeWidth="0.27325"
      />
      <g opacity="0.1">
        <mask
          id="mask0_4865_37294"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="7"
          width="18"
          height="18"
        >
          <rect
            x="6.6936"
            y="7.66431"
            width="16.6708"
            height="16.6708"
            rx="1.65595"
            fill="#C4C4C4"
            stroke="#0A2246"
            strokeWidth="0.027831"
          />
        </mask>
        <g mask="url(#mask0_4865_37294)">
          <path
            d="M13.0684 7.67871H13.8079V8.41822H13.0684V7.67871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 7.67871H14.6033V8.41822H13.8638V7.67871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 7.67871H15.3987V8.41822H14.6592V7.67871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 7.67871H16.989V8.41822H16.2495V7.67871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 8.47412H14.6033V9.21363H13.8638V8.47412Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 8.47412H15.3987V9.21363H14.6592V8.47412Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 9.26904H15.3987V10.0086H14.6592V9.26904Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 10.064H13.8079V10.8035H13.0684V10.064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 10.064H16.1936V10.8035H15.4541V10.064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 10.064H16.989V10.8035H16.2495V10.064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 10.8599H15.3987V11.5994H14.6592V10.8599Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 10.8599H16.1936V11.5994H15.4541V10.8599Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 11.6553H14.6033V12.3948H13.8638V11.6553Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 11.6553H16.1936V12.3948H15.4541V11.6553Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 12.4497H13.8079V13.1892H13.0684V12.4497Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 12.4497H15.3987V13.1892H14.6592V12.4497Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 12.4497H16.989V13.1892H16.2495V12.4497Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 13.2446H14.6033V13.9841H13.8638V13.2446Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 13.2446H16.989V13.9841H16.2495V13.2446Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M8.29736 14.04H9.03687V14.7795H8.29736V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.88818 14.04H10.6277V14.7795H9.88818V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M10.6831 14.04H11.4226V14.7795H10.6831V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M11.4785 14.04H12.218V14.7795H11.4785V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 14.04H13.8079V14.7795H13.0684V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 14.04H14.6033V14.7795H13.8638V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 14.04H17.7844V14.7795H17.0449V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 14.04H20.9646V14.7795H20.2251V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 14.04H23.3503V14.7795H22.6108V14.04Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.09277 14.835H9.83228V15.5745H9.09277V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.88818 14.835H10.6277V15.5745H9.88818V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M12.2729 14.835H13.0125V15.5745H12.2729V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 14.835H13.8079V15.5745H13.0684V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 14.835H16.989V15.5745H16.2495V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 14.835H18.5794V15.5745H17.8398V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 14.835H19.3738V15.5745H18.6343V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 14.835H22.5549V15.5745H21.8154V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 14.835H23.3503V15.5745H22.6108V14.835Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M6.70703 15.6304H7.44654V16.3699H6.70703V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M7.50244 15.6304H8.24196V16.3699H7.50244V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M8.29736 15.6304H9.03687V16.3699H8.29736V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.09277 15.6304H9.83228V16.3699H9.09277V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.88818 15.6304H10.6277V16.3699H9.88818V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M11.4785 15.6304H12.218V16.3699H11.4785V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 15.6304H13.8079V16.3699H13.0684V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 15.6304H16.989V16.3699H16.2495V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 15.6304H17.7844V16.3699H17.0449V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 15.6304H18.5794V16.3699H17.8398V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 15.6304H19.3738V16.3699H18.6343V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 15.6304H20.9646V16.3699H20.2251V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.0205 15.6304H21.76V16.3699H21.0205V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 15.6304H22.5549V16.3699H21.8154V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 15.6304H23.3503V16.3699H22.6108V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M6.70703 16.4258H7.44654V17.1653H6.70703V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M7.50244 16.4258H8.24196V17.1653H7.50244V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M8.29736 16.4258H9.03687V17.1653H8.29736V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.09277 16.4258H9.83228V17.1653H9.09277V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M10.6831 16.4258H11.4226V17.1653H10.6831V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 16.4258H13.8079V17.1653H13.0684V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 16.4258H14.6033V17.1653H13.8638V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 16.4258H16.1936V17.1653H15.4541V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 16.4258H16.989V17.1653H16.2495V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 16.4258H19.3738V17.1653H18.6343V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 16.4258H20.1697V17.1653H19.4302V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 16.4258H22.5549V17.1653H21.8154V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M6.70703 17.2207H7.44654V17.9602H6.70703V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M7.50244 17.2207H8.24196V17.9602H7.50244V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M10.6831 17.2207H11.4226V17.9602H10.6831V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M11.4785 17.2207H12.218V17.9602H11.4785V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 17.2207H13.8079V17.9602H13.0684V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 17.2207H14.6033V17.9602H13.8638V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 17.2207H15.3987V17.9602H14.6592V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 17.2207H19.3738V17.9602H18.6343V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 17.2207H20.9646V17.9602H20.2251V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 17.2207H22.5549V17.9602H21.8154V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 17.2207H23.3503V17.9602H22.6108V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 18.0161H13.8079V18.7556H13.0684V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 18.0161H15.3987V18.7556H14.6592V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 18.0161H16.1936V18.7556H15.4541V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 18.0161H16.989V18.7556H16.2495V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 18.0161H20.9646V18.7556H20.2251V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 18.0161H23.3503V18.7556H22.6108V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 18.8115H14.6033V19.551H13.8638V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 18.8115H15.3987V19.551H14.6592V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 18.8115H20.1697V19.551H19.4302V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 18.8115H20.9646V19.551H20.2251V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 18.8115H22.5549V19.551H21.8154V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 18.8115H23.3503V19.551H22.6108V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 19.6064H13.8079V20.346H13.0684V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 19.6064H14.6033V20.346H13.8638V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 19.6064H15.3987V20.346H14.6592V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 19.6064H16.989V20.346H16.2495V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 19.6064H18.5794V20.346H17.8398V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 19.6064H22.5549V20.346H21.8154V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 20.4009H13.8079V21.1404H13.0684V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 20.4009H15.3987V21.1404H14.6592V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 20.4009H16.1936V21.1404H15.4541V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 20.4009H18.5794V21.1404H17.8398V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 20.4009H20.1697V21.1404H19.4302V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 20.4009H20.9646V21.1404H20.2251V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 20.4009H22.5549V21.1404H21.8154V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 20.4009H23.3503V21.1404H22.6108V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 21.1963H14.6033V21.9358H13.8638V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 21.1963H18.5794V21.9358H17.8398V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 21.1963H19.3738V21.9358H18.6343V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 21.1963H22.5549V21.9358H21.8154V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 21.9922H13.8079V22.7317H13.0684V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 21.9922H16.989V22.7317H16.2495V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 21.9922H17.7844V22.7317H17.0449V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 21.9922H19.3738V22.7317H18.6343V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.0205 21.9922H21.76V22.7317H21.0205V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 21.9922H23.3503V22.7317H22.6108V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 22.7871H14.6033V23.5266H13.8638V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 22.7871H15.3987V23.5266H14.6592V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 22.7871H16.1936V23.5266H15.4541V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 22.7871H17.7844V23.5266H17.0449V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 22.7871H18.5794V23.5266H17.8398V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 22.7871H19.3738V23.5266H18.6343V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 22.7871H20.1697V23.5266H19.4302V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 22.7871H20.9646V23.5266H20.2251V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 22.7871H22.5549V23.5266H21.8154V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 23.582H15.3987V24.3215H14.6592V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 23.582H17.7844V24.3215H17.0449V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 23.582H18.5794V24.3215H17.8398V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 23.582H19.3738V24.3215H18.6343V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.0205 23.582H21.76V24.3215H21.0205V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 23.582H22.5549V24.3215H21.8154V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 23.582H23.3503V24.2937C23.3503 24.3091 23.3379 24.3215 23.3225 24.3215H22.6108V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.426 9.24536H20.6844C21.2915 9.24536 21.7837 9.73755 21.7837 10.3447V11.603H19.426V9.24536Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.027831"
          />
          <path
            d="M10.6316 9.24536H9.37323C8.76609 9.24536 8.27391 9.73755 8.27391 10.3447V11.603H10.6316V9.24536Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.027831"
          />
          <path
            d="M10.6609 22.7551H9.40253C8.79539 22.7551 8.3032 22.2629 8.3032 21.6558V20.3974H10.6609V22.7551Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.027831"
          />
          <path
            d="M18.1938 8.01268H21.1519C22.1817 8.01268 23.0166 8.84753 23.0166 9.87736V12.8354H18.1938V8.01268Z"
            stroke="#0A2246"
            strokeWidth="0.723607"
          />
          <path
            d="M11.8638 8.01268H8.90574C7.87591 8.01268 7.04106 8.84753 7.04106 9.87736V12.8354H11.8638V8.01268Z"
            stroke="#0A2246"
            strokeWidth="0.723607"
          />
          <path
            d="M11.8936 23.9878H8.93553C7.90569 23.9878 7.07085 23.153 7.07085 22.1231V19.1651H11.8936V23.9878Z"
            stroke="#0A2246"
            strokeWidth="0.723607"
          />
        </g>
      </g>
      <g opacity="0.6">
        <mask
          id="mask1_4865_37294"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="16"
          width="18"
          height="9"
        >
          <path
            d="M6.6936 16.0139H23.3644V22.6794C23.3644 23.594 22.623 24.3354 21.7084 24.3354H8.34955C7.435 24.3354 6.6936 23.594 6.6936 22.6794V16.0139Z"
            fill="#C4C4C4"
            stroke="#0096D6"
            strokeWidth="0.027831"
          />
        </mask>
        <g mask="url(#mask1_4865_37294)">
          <path
            d="M6.70703 15.6304H7.44654V16.3699H6.70703V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M7.50244 15.6304H8.24196V16.3699H7.50244V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M8.29736 15.6304H9.03687V16.3699H8.29736V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.09277 15.6304H9.83228V16.3699H9.09277V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.88818 15.6304H10.6277V16.3699H9.88818V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M11.4785 15.6304H12.218V16.3699H11.4785V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 15.6304H13.8079V16.3699H13.0684V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 15.6304H16.989V16.3699H16.2495V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 15.6304H17.7844V16.3699H17.0449V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 15.6304H18.5794V16.3699H17.8398V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 15.6304H19.3738V16.3699H18.6343V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 15.6304H20.9646V16.3699H20.2251V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.0205 15.6304H21.76V16.3699H21.0205V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 15.6304H22.5549V16.3699H21.8154V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 15.6304H23.3503V16.3699H22.6108V15.6304Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M6.70703 16.4258H7.44654V17.1653H6.70703V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M7.50244 16.4258H8.24196V17.1653H7.50244V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M8.29736 16.4258H9.03687V17.1653H8.29736V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M9.09277 16.4258H9.83228V17.1653H9.09277V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M10.6831 16.4258H11.4226V17.1653H10.6831V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 16.4258H13.8079V17.1653H13.0684V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 16.4258H14.6033V17.1653H13.8638V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 16.4258H16.1936V17.1653H15.4541V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 16.4258H16.989V17.1653H16.2495V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 16.4258H19.3738V17.1653H18.6343V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 16.4258H20.1697V17.1653H19.4302V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 16.4258H22.5549V17.1653H21.8154V16.4258Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M6.70703 17.2207H7.44654V17.9602H6.70703V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M7.50244 17.2207H8.24196V17.9602H7.50244V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M10.6831 17.2207H11.4226V17.9602H10.6831V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M11.4785 17.2207H12.218V17.9602H11.4785V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 17.2207H13.8079V17.9602H13.0684V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 17.2207H14.6033V17.9602H13.8638V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 17.2207H15.3987V17.9602H14.6592V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 17.2207H19.3738V17.9602H18.6343V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 17.2207H20.9646V17.9602H20.2251V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 17.2207H22.5549V17.9602H21.8154V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 17.2207H23.3503V17.9602H22.6108V17.2207Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 18.0161H13.8079V18.7556H13.0684V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 18.0161H15.3987V18.7556H14.6592V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 18.0161H16.1936V18.7556H15.4541V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 18.0161H16.989V18.7556H16.2495V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 18.0161H20.9646V18.7556H20.2251V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 18.0161H23.3503V18.7556H22.6108V18.0161Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 18.8115H14.6033V19.551H13.8638V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 18.8115H15.3987V19.551H14.6592V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 18.8115H20.1697V19.551H19.4302V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 18.8115H20.9646V19.551H20.2251V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 18.8115H22.5549V19.551H21.8154V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 18.8115H23.3503V19.551H22.6108V18.8115Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 19.6064H13.8079V20.346H13.0684V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 19.6064H14.6033V20.346H13.8638V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 19.6064H15.3987V20.346H14.6592V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 19.6064H16.989V20.346H16.2495V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 19.6064H18.5794V20.346H17.8398V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 19.6064H22.5549V20.346H21.8154V19.6064Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 20.4009H13.8079V21.1404H13.0684V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 20.4009H15.3987V21.1404H14.6592V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 20.4009H16.1936V21.1404H15.4541V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 20.4009H18.5794V21.1404H17.8398V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 20.4009H20.1697V21.1404H19.4302V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 20.4009H20.9646V21.1404H20.2251V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 20.4009H22.5549V21.1404H21.8154V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 20.4009H23.3503V21.1404H22.6108V20.4009Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 21.1963H14.6033V21.9358H13.8638V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 21.1963H18.5794V21.9358H17.8398V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 21.1963H19.3738V21.9358H18.6343V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 21.1963H22.5549V21.9358H21.8154V21.1963Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.0684 21.9922H13.8079V22.7317H13.0684V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M16.2495 21.9922H16.989V22.7317H16.2495V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 21.9922H17.7844V22.7317H17.0449V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 21.9922H19.3738V22.7317H18.6343V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.0205 21.9922H21.76V22.7317H21.0205V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 21.9922H23.3503V22.7317H22.6108V21.9922Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M13.8638 22.7871H14.6033V23.5266H13.8638V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 22.7871H15.3987V23.5266H14.6592V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M15.4541 22.7871H16.1936V23.5266H15.4541V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 22.7871H17.7844V23.5266H17.0449V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 22.7871H18.5794V23.5266H17.8398V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 22.7871H19.3738V23.5266H18.6343V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M19.4302 22.7871H20.1697V23.5266H19.4302V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M20.2251 22.7871H20.9646V23.5266H20.2251V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 22.7871H22.5549V23.5266H21.8154V22.7871Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M14.6592 23.582H15.3987V24.3215H14.6592V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.0449 23.582H17.7844V24.3215H17.0449V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M17.8398 23.582H18.5794V24.3215H17.8398V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M18.6343 23.582H19.3738V24.3215H18.6343V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.0205 23.582H21.76V24.3215H21.0205V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M21.8154 23.582H22.5549V24.3215H21.8154V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M22.6108 23.582H23.3503V24.2937C23.3503 24.3091 23.3379 24.3215 23.3225 24.3215H22.6108V23.582Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.0556621"
          />
          <path
            d="M10.6609 22.7551H9.40253C8.79539 22.7551 8.3032 22.2629 8.3032 21.6558V20.3974H10.6609V22.7551Z"
            fill="#0A2246"
            stroke="#0A2246"
            strokeWidth="0.027831"
          />
          <path
            d="M11.8936 23.9878H8.93553C7.90569 23.9878 7.07085 23.153 7.07085 22.1231V19.1651H11.8936V23.9878Z"
            stroke="#0A2246"
            strokeWidth="0.723607"
          />
        </g>
      </g>
      <rect
        x="6.67969"
        y="14.6641"
        width="16.6986"
        height="2.67178"
        fill="white"
      />
      <path
        d="M3.11719 16H26.9406V27.5777C26.9406 27.7622 26.791 27.9117 26.6066 27.9117H3.45116C3.26671 27.9117 3.11719 27.7622 3.11719 27.5777V16Z"
        fill="url(#paint1_linear_4865_37294)"
      />
      <g filter="url(#filter0_d_4865_37294)">
        <path
          d="M19.799 4.3667H22.2098C24.6691 4.3667 26.6627 6.36036 26.6627 8.81966V11.2304M19.799 27.6334H22.2098C24.6691 27.6334 26.6627 25.6398 26.6627 23.1805V20.7697M10.2597 27.6334H7.84896C5.38966 27.6334 3.396 25.6398 3.396 23.1805L3.396 20.7697M3.396 11.2304L3.396 8.81967C3.396 6.36036 5.38966 4.3667 7.84896 4.3667H10.2597"
          stroke="url(#paint2_linear_4865_37294)"
          strokeWidth="1.55854"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect
        x="1.72607"
        y="15.3315"
        width="26.6065"
        height="1.33589"
        rx="0.667945"
        fill="url(#paint3_linear_4865_37294)"
      />
      <mask
        id="path-233-outside-1_4865_37294"
        maskUnits="userSpaceOnUse"
        x="34.5225"
        y="5.3252"
        width="57"
        height="21"
        fill="black"
      >
        <rect fill="white" x="34.5225" y="5.3252" width="57" height="21" />
        <path d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z" />
        <path d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z" />
        <path d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z" />
        <path d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z" />
        <path d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z" />
        <path d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z" />
        <path d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z" />
        <path d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z" />
        <path d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z" />
        <path d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z" />
        <path d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z" />
        <path d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z" />
        <path d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z" />
        <path d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z" />
      </mask>
      <path
        d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z"
        fill="#304AB4"
      />
      <path
        d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z"
        fill="url(#paint4_linear_4865_37294)"
      />
      <path
        d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z"
        fill="white"
      />
      <path
        d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z"
        fill="#304AB4"
      />
      <path
        d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z"
        fill="url(#paint5_linear_4865_37294)"
      />
      <path
        d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z"
        fill="white"
      />
      <path
        d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z"
        fill="#304AB4"
      />
      <path
        d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z"
        fill="url(#paint6_linear_4865_37294)"
      />
      <path
        d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z"
        fill="white"
      />
      <path
        d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z"
        fill="#304AB4"
      />
      <path
        d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z"
        fill="url(#paint7_linear_4865_37294)"
      />
      <path
        d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z"
        fill="white"
      />
      <path
        d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z"
        fill="#304AB4"
      />
      <path
        d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z"
        fill="url(#paint8_linear_4865_37294)"
      />
      <path
        d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z"
        fill="white"
      />
      <path
        d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z"
        fill="#304AB4"
      />
      <path
        d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z"
        fill="url(#paint9_linear_4865_37294)"
      />
      <path
        d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z"
        fill="white"
      />
      <path
        d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z"
        fill="#304AB4"
      />
      <path
        d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z"
        fill="url(#paint10_linear_4865_37294)"
      />
      <path
        d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z"
        fill="white"
      />
      <path
        d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z"
        fill="#304AB4"
      />
      <path
        d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z"
        fill="url(#paint11_linear_4865_37294)"
      />
      <path
        d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z"
        fill="white"
      />
      <path
        d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z"
        fill="#304AB4"
      />
      <path
        d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z"
        fill="url(#paint12_linear_4865_37294)"
      />
      <path
        d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z"
        fill="white"
      />
      <path
        d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z"
        fill="#304AB4"
      />
      <path
        d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z"
        fill="url(#paint13_linear_4865_37294)"
      />
      <path
        d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z"
        fill="white"
      />
      <path
        d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z"
        fill="#304AB4"
      />
      <path
        d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z"
        fill="url(#paint14_linear_4865_37294)"
      />
      <path
        d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z"
        fill="white"
      />
      <path
        d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z"
        fill="#304AB4"
      />
      <path
        d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z"
        fill="url(#paint15_linear_4865_37294)"
      />
      <path
        d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z"
        fill="white"
      />
      <path
        d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z"
        fill="#304AB4"
      />
      <path
        d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z"
        fill="url(#paint16_linear_4865_37294)"
      />
      <path
        d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z"
        fill="white"
      />
      <path
        d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z"
        fill="#304AB4"
      />
      <path
        d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z"
        fill="url(#paint17_linear_4865_37294)"
      />
      <path
        d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z"
        fill="white"
      />
      <path
        d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z"
        stroke="url(#paint18_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M40.1028 6.3252C40.8133 6.33203 41.3598 6.50281 41.7423 6.83754C42.1317 7.16544 42.3264 7.64021 42.3264 8.26186C42.3264 8.43264 42.3196 8.56585 42.3059 8.66148L42.2444 9.18407C42.1488 9.93551 41.8619 10.5128 41.3837 10.9158C40.9055 11.312 40.2668 11.5135 39.4675 11.5204H37.3771L37.0697 14.0206H35.8606L36.8033 6.3252H40.1028ZM39.6315 10.3932C40.055 10.3864 40.3795 10.2942 40.6049 10.1165C40.8303 9.9321 40.9704 9.64177 41.025 9.24556L41.107 8.6205C41.1138 8.57268 41.1173 8.50778 41.1173 8.42581C41.1173 8.09107 41.025 7.84856 40.8406 7.69828C40.663 7.54116 40.3829 7.45918 40.0003 7.45235H37.8792L37.5104 10.3932H39.6315Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z"
        stroke="url(#paint19_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M48.1439 6.3252C48.8407 6.33203 49.3769 6.49598 49.7527 6.81705C50.1284 7.13128 50.3162 7.58898 50.3162 8.19013C50.3162 8.26527 50.3094 8.38482 50.2957 8.54877L50.2548 8.88692C50.1728 9.50856 49.9542 10.0004 49.599 10.3625C49.2506 10.7245 48.7792 10.9534 48.1849 11.049L50.2752 14.0206H48.7997L46.8528 11.1002H45.449L45.0903 14.0206H43.8812L44.8239 6.3252H48.1439ZM47.7238 9.99358C48.1132 9.98675 48.4137 9.90477 48.6255 9.74765C48.8441 9.59053 48.9773 9.3207 49.0251 8.93815L49.0866 8.49753C49.0934 8.44971 49.0969 8.38482 49.0969 8.30284C49.0969 7.99544 49.008 7.77684 48.8304 7.64704C48.6528 7.51042 48.3898 7.43869 48.0414 7.43186H45.8998L45.5822 9.99358H47.7238Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z"
        stroke="url(#paint20_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M53.2147 6.3252H58.1742L58.041 7.43186H54.2907L54.0242 9.57346H57.3032L57.17 10.6699H53.891L53.6144 12.9037H57.4569L57.3237 14.0206H52.272L53.2147 6.3252Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z"
        stroke="url(#paint21_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M61.8369 6.3252L63.5277 12.6168H63.5687L66.8169 6.3252H68.149L67.2063 14.0206H66.0996L66.7657 8.58976L64.1117 13.7029C64.0024 13.9147 63.8317 14.0206 63.5994 14.0206H63.1793C62.9402 14.0206 62.7967 13.9147 62.7489 13.7029L61.3348 8.58976L60.6688 14.0206H59.5621L60.5048 6.3252H61.8369Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z"
        stroke="url(#paint22_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M70.7369 6.3252H71.946L71.0033 14.0206H69.7942L70.7369 6.3252Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z"
        stroke="url(#paint23_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M77.0246 12.9344C77.66 12.9276 78.1313 12.7705 78.4387 12.4631C78.7461 12.1557 78.9476 11.657 79.0433 10.967L79.6069 6.3252H80.816L80.2627 10.9056C80.1192 12.0054 79.7811 12.8046 79.2482 13.3033C78.7154 13.802 77.9298 14.0548 76.8914 14.0616H76.4918C75.5833 14.0548 74.9172 13.8601 74.4937 13.4775C74.0701 13.095 73.8584 12.4938 73.8584 11.6741C73.8584 11.4691 73.8754 11.213 73.9096 10.9056L74.4732 6.3252H75.6823L75.1187 10.967C75.0914 11.2403 75.0777 11.4247 75.0777 11.5204C75.0777 12.019 75.1973 12.3777 75.4364 12.5963C75.6755 12.8149 76.0717 12.9276 76.625 12.9344H77.0246Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z"
        stroke="url(#paint24_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M84.6879 6.3252L86.3786 12.6168H86.4196L89.6679 6.3252H90.9999L90.0572 14.0206H88.9506L89.6166 8.58976L86.9627 13.7029C86.8534 13.9147 86.6826 14.0206 86.4503 14.0206H86.0302C85.7911 14.0206 85.6477 13.9147 85.5998 13.7029L84.1858 8.58976L83.5197 14.0206H82.4131L83.3558 6.3252H84.6879Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z"
        stroke="url(#paint25_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M37.7358 25.6747C36.8409 25.6747 36.1031 25.6303 35.5225 25.5415L35.6659 24.3939C36.4993 24.4963 37.2337 24.5476 37.869 24.5476H38.0022C38.4326 24.5476 38.7707 24.5066 39.0166 24.4246C39.2694 24.3426 39.4504 24.2231 39.5597 24.066C39.669 23.9088 39.7373 23.7073 39.7647 23.4614C39.7783 23.3384 39.7852 23.253 39.7852 23.2052C39.7852 22.9661 39.7305 22.7851 39.6212 22.6621C39.5119 22.5392 39.348 22.4538 39.1294 22.406C38.9176 22.3513 38.5965 22.2967 38.1662 22.242C37.6743 22.1874 37.2849 22.102 36.998 21.9858C36.7111 21.8697 36.4959 21.7023 36.3525 21.4837C36.2158 21.2583 36.1475 20.9577 36.1475 20.582C36.1475 20.4317 36.1578 20.2644 36.1783 20.0799C36.2671 19.308 36.5267 18.7581 36.957 18.4302C37.3874 18.1023 38.0466 17.9383 38.9347 17.9383H39.652C40.1711 17.9383 40.745 17.9452 41.3734 17.9588L41.2402 19.086C40.8782 19.086 40.6015 19.0826 40.4102 19.0757L39.5187 19.0655H38.8015C38.3028 19.0655 37.9373 19.1543 37.705 19.3319C37.4796 19.5027 37.3669 19.8306 37.3669 20.3156C37.3669 20.5274 37.4079 20.6879 37.4899 20.7972C37.5718 20.8997 37.6846 20.9714 37.828 21.0124C37.9715 21.0534 38.2037 21.0978 38.5248 21.1456C38.5863 21.1524 38.7092 21.1695 38.8937 21.1968C39.3992 21.272 39.8022 21.371 40.1028 21.494C40.4034 21.617 40.6288 21.7946 40.7791 22.0268C40.9294 22.2523 41.0045 22.5597 41.0045 22.949C41.0045 23.0925 40.9943 23.2462 40.9738 23.4102C40.9055 23.9772 40.7552 24.4246 40.5229 24.7525C40.2975 25.0804 39.9696 25.3161 39.5392 25.4595C39.1089 25.603 38.5521 25.6747 37.869 25.6747H37.7358Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z"
        stroke="url(#paint26_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M43.8633 17.9383H48.8228L48.6895 19.045H44.9392L44.6728 21.1866H47.9518L47.8186 22.283H44.5396L44.2629 24.5168H48.1055L47.9723 25.6337H42.9205L43.8633 17.9383Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z"
        stroke="url(#paint27_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M54.4733 17.9383C55.1701 17.9452 55.7064 18.1091 56.0821 18.4302C56.4578 18.7444 56.6457 19.2021 56.6457 19.8033C56.6457 19.8784 56.6389 19.9979 56.6252 20.1619L56.5842 20.5C56.5022 21.1217 56.2836 21.6135 55.9284 21.9756C55.58 22.3377 55.1087 22.5665 54.5143 22.6621L56.6047 25.6337H55.1291L53.1822 22.7134H51.7784L51.4198 25.6337H50.2106L51.1534 17.9383H54.4733ZM54.0532 21.6067C54.4426 21.5999 54.7432 21.5179 54.9549 21.3608C55.1735 21.2037 55.3068 20.9338 55.3546 20.5513L55.4161 20.1107C55.4229 20.0628 55.4263 19.9979 55.4263 19.916C55.4263 19.6086 55.3375 19.39 55.1599 19.2602C54.9823 19.1235 54.7193 19.0518 54.3709 19.045H52.2293L51.9116 21.6067H54.0532Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z"
        stroke="url(#paint28_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M60.7328 25.6337C60.3844 25.6337 60.1727 25.4151 60.0975 24.9779L58.9396 17.9383H60.2205L61.2452 24.5066H62.1059L64.7599 17.9383H66.0817L63.1716 25.0702C63.0896 25.2751 62.9906 25.4185 62.8744 25.5005C62.7583 25.5893 62.6183 25.6337 62.4543 25.6337H60.7328Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z"
        stroke="url(#paint29_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M68.0651 17.9383H69.2742L68.3315 25.6337H67.1224L68.0651 17.9383Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z"
        stroke="url(#paint30_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M75.1214 17.9383C75.4083 17.9383 75.8182 17.9452 76.351 17.9588L76.9863 17.9691L76.8326 19.1065L76.2383 19.086C75.5552 19.0723 75.1282 19.0655 74.9574 19.0655H74.5066C73.8849 19.0655 73.4204 19.2226 73.113 19.5368C72.8124 19.8511 72.6143 20.36 72.5187 21.0636L72.3342 22.5494C72.3069 22.8227 72.2932 23.0139 72.2932 23.1232C72.2932 23.6219 72.4094 23.984 72.6416 24.2094C72.8807 24.4348 73.2701 24.5476 73.8098 24.5476H74.3016C74.6637 24.5476 75.2512 24.5237 76.0641 24.4758L76.3203 24.4656L76.2383 25.5927C75.5688 25.6474 74.8891 25.6747 74.1991 25.6747H73.7073C72.8056 25.6679 72.1429 25.4732 71.7194 25.0906C71.2959 24.7081 71.0841 24.1069 71.0841 23.2872C71.0841 23.0754 71.1012 22.809 71.1353 22.4879L71.289 21.1251C71.4325 20.0116 71.7706 19.2055 72.3035 18.7068C72.8431 18.2013 73.6322 17.9452 74.6705 17.9383H75.1214Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z"
        stroke="#304AB4"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z"
        stroke="url(#paint31_linear_4865_37294)"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <path
        d="M79.4632 17.9383H84.4227L84.2894 19.045H80.5391L80.2727 21.1866H83.5517L83.4185 22.283H80.1395L79.8628 24.5168H83.7054L83.5722 25.6337H78.5204L79.4632 17.9383Z"
        stroke="white"
        strokeWidth="0.683125"
        mask="url(#path-233-outside-1_4865_37294)"
      />
      <defs>
        <filter
          id="filter0_d_4865_37294"
          x="2.06008"
          y="3.5874"
          width="25.9384"
          height="25.9384"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.556621" />
          <feGaussianBlur stdDeviation="0.27831" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.391355 0 0 0 0 0.558333 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4865_37294"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4865_37294"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4865_37294"
          x1="15.0288"
          y1="0.971191"
          x2="15.0288"
          y2="31.0287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FDFEFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4865_37294"
          x1="15.0289"
          y1="16"
          x2="15.0289"
          y2="24.3493"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0096D6" stopOpacity="0.4" />
          <stop offset="0.609784" stopColor="#0096D6" stopOpacity="0.06" />
          <stop offset="1" stopColor="#0096D6" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4865_37294"
          x1="-15.0281"
          y1="-23.2417"
          x2="22.5438"
          y2="36.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A2D7D" />
          <stop offset="0.598065" stopColor="#6271FF" />
          <stop offset="1" stopColor="#5BD3FF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4865_37294"
          x1="1.72607"
          y1="15.9995"
          x2="28.3325"
          y2="15.9995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#646DFF" />
          <stop offset="0.594082" stopColor="#5BD0FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_4865_37294"
          x1="29.9053"
          y1="29.6585"
          x2="93.0676"
          y2="25.5036"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ffffff" />
          <stop offset="1" stopColor="#ffffff" />
        </linearGradient>
      </defs>
    </svg>
  )
);

export default SPTopLogo;
